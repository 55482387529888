import { Autocomplete, Button, Dialog, DialogContent, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Menu, MenuItem, Radio, RadioGroup, Switch, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { FaArrowLeft } from "react-icons/fa6";
import DataContext from '../../../context/DataContext';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import notifyService from '../../../api/notifySerivce';
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import cryptodecryptJS from '../../../hooks/cryptodecryptJS';
import cryptoJS from '../../../hooks/cryptoJS';
import Viewimage from '../../../api/Viewimage';
import { FaInfoCircle } from "react-icons/fa";
import ReactQuill from 'react-quill';
import { formats } from "./EditorToolbar";
import CloudDoneIcon from '@mui/icons-material/CloudDone';

const today = dayjs();
const tomorrow = dayjs().add(0, 'day');
const AdminEventAdd = () => {
  const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+)\.[a-zA-Z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;
  const { id } = useParams()
  let eventId
  if (id !== undefined) {
    eventId = atob(id)
  }
  let depId = localStorage.getItem('departmentId')
  const { navigator, validateNumberonly, setPageLoading, VisuallyHiddenInput, toolbarOptions } = useContext(DataContext);

  const [formData, setFormData] = useState({});
  const [publishBtn, setPublishBtn] = useState(false)
  const { handleSubmit, control, setValue, reset, clearErrors, getValues, setError, formState: { errors } } = useForm({ values: formData });
  useDidMountEffect(() => {
    getEventCategoryList()
    getEventServiceList()
    getParsonaList()
    dropdownApi()
    if (eventId) {
      getApi(eventId)
    }
  }, [])
  const [district, setDistrict] = useState([]);
  const dropdownApi = () => {
    const req = {
      lang:"ENG"
    }
    apiService(`home/district/list1`,req, "post")
      .then((result) => {
        if (result && result.data && result.data.districts) {
          var values = result.data.districts;
          setDistrict(values);
        }
      })
      .catch((err) => { });
  };
  const handleSelectDistrict = (value) => {
    setValue("district", value);
    clearErrors("district");
  };
  const [publishId, setPublishId] = useState(0)
  const getApi = (val) => {
    setPageLoading(true);
    apiService(`event/get?id=${val}`, '', 'get').then((result) => {
      setPageLoading(false);
      if (result && result.data) {
        var data = result.data
        data.paid = data.paid === true ? 'paid' : 'free'
        if (data.id) {
          setPublishId(data.id)
        }
        if (data.imageName) {
          data.imageUrl = data.imageName
        }
        if (data.startDate) {
          data.startDate = dayjs(data.startDate, "YYYY-MM-DD");
        }
        if (data.endDate) {
          data.endDate = dayjs(data.endDate, "YYYY-MM-DD");
        }

        data.registrationEndDate = dayjs(data.registrationEndDate, "YYYY-MM-DD");
        if (data.startTime) {
          data.startTime = dayjs(data.startTime, "HH:mm");
        }
        if (data.endTime) {
          data.endTime = dayjs(data.endTime, "HH:mm");
        }
        if (data.managementTeam) {
          data.managementTeam.forEach(element => {
            if (element.email) {
              element.email = cryptodecryptJS(element.email);
            }

          });
        }
        if (data.publish) {
          setPublishBtn(data.publish)
        }

        if (data.mode === 'Offline') {
          data.venueId = data.venue
          getVenusNameList()

        }
        if(data.amount == 0){
          data.amount = ""
        }
        // data.paid = data.paid === true ? true : false
        setFormData(data)
      }
    }).catch((err) => {
      console.log(err);

    });
  }
  const formatDateTime = (dateString) => {
    return dayjs(dateString).format('DD/MM/YYYY HH:mm');
  };
  const handleEventMode = (value) => {
    setValue("mode", value);
    setValue('venue', "")
    setValue('mapLink', "")
    setValue('district', "")
    setValue('meetLink', "")
    clearErrors('mode')
    if (value === 'Offline') {
      getVenusNameList()
    }
  }
  const handleFeeMode = (value) => {
    setValue("paid", value);
    clearErrors('paid')
    if (value === 'free') {
      setValue('amount', '')
      clearErrors('amount')
    }
  }

  // const [startdate, setStartDate] = useState(null)
  const handleStartDateFormat = (newValue) => {
    setStartDate(newValue)
    setValue('startDate', newValue)
    clearErrors('startDate');
    // const datePart = newValue.format('YYYY-MM-DD');
  }
  // const [enddate, setEndDate] = useState(null)
  const handleEndDateFormat = (newValue) => {
    setEndDate(newValue)
    setValue('endDate', newValue)
    clearErrors('endDate');
    // const datePart = newValue.format('YYYY-MM-DD');
  }

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [registrationDate, setregistrationDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(false)
  const handleStartDateChange = (newValue) => {
    if (endDate && dayjs(newValue).isAfter(endDate)) {
      notifyService(
        "danger",
        "Start Date cannot be after End Date"
      );
      setError('startDate', {
        type: 'manual',
        message: 'Start Date cannot be after End Date',
      });
      setSelectedDate(true)
    } else {
      setStartDate(newValue);
      setValue('startDate', newValue)
      clearErrors('startDate');
    }
  };

  const handleEndDateChange = (newValue) => {
    if (startDate && dayjs(newValue).isBefore(startDate)) {
      notifyService(
        "danger",
        "End Date cannot be before Start Date"
      );
      setError('endDate', {
        type: 'manual',
        message: 'End Date cannot be before Start Date',
      });
    } else {
      setEndDate(newValue);
      setValue('endDate', newValue)
      clearErrors('endDate');
    }
  };

  const handleRegistrationDateChange = (newValue) => {
    if (endDate && dayjs(newValue).isAfter(endDate)) {
      notifyService(
        "danger",
        "Registration Date cannot be after End Date"
      );
      setError('registrationEndDate', {
        type: 'manual',
        message: 'Registration Date cannot be after End Date',
      });
    } else {
      setregistrationDate(newValue)
      setValue('registrationEndDate', newValue)
      clearErrors('registrationEndDate');
    }
  };

  const [regdate, setRegdate] = useState(null)
  const handleRegDateFormat = (newValue) => {


    const today = dayjs();

    // if (dayjs(newValue).isBefore(today, 'day')) {
    //   setError('registrationEndDate', {
    //     type: 'manual',
    //     message: 'Registration End Date cannot be in the past',
    //   });
    // } else {
    setRegdate(newValue);
    setValue('registrationEndDate', newValue);
    clearErrors('registrationEndDate');
    // }

  }

  const [startTime, setStartTime] = useState(null)
  const handleStartTimeFormat = (newValue) => {
    setStartTime(newValue)
    setValue('startTime', newValue)
    clearErrors('startTime');
  }
  const [endTime, setEndTime] = useState(null)
  const handleEndTimeFormat = (newValue) => {
    setEndTime(newValue)
    setValue('endTime', newValue)
    clearErrors('endTime');
  }
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const [viewImageName, setViewImageName] = useState("");
  const handleClickOpen = (value, name) => {
    setViewImage(value);
    setViewImageName(name);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName, fileUrl, format) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (
            ((format === 'file') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf")) ||
            ((format === 'image') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png"))
          ) {
            var currentImageName = getValues(fileName);
            if (currentImageName && currentImageName !== logoselecetdFile.name) {
              notifyService(
                "success",
                "Image Updated",
                "The image has been successfully changed."
              );
            }
            setValue(filekey, "");
            setValue(fileName, "");
            setValue(fileUrl, '')
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              // setUrl(logourl1)
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              setValue(fileUrl, logourl1)
              clearErrors(filekey);
              clearErrors(fileName);
              clearErrors(fileUrl);
              // 
              // clearErrors('dpiitCertificateName')
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File Size Exceeded",
            `Maximum file size allowed is 5 MB`
          );
        }
      }
    }
  };
  const [eventCategoryList, setEventCategoryList] = useState(['category 1', 'category 2'])
  const getEventCategoryList = () => {
    apiService(`home/category/list?type=${'event'}`, '', 'unauthget').then((result) => {
      if (result && result.data && result.data.categories) {
        setEventCategoryList(result.data.categories);
      }
    }).catch((err) => {

    });
  }
  const handleSelectEventCategory = (value) => {
    setValue('category', value)
    clearErrors('category')
  }

  const [eventServiceList, setEventServiceList] = useState([])
  const getEventServiceList = () => {
    apiService(`home/service/list`, '', 'unauthget').then((result) => {
      if (result && result.data && result.data.services) {
        setEventServiceList(result.data.services);
      }
    }).catch((err) => {

    });
  }
  const handleSelectEventService = (value) => {
    setValue('service', value)
    clearErrors('service')
  }

  const [eventVenueList, setEventVenueList] = useState([])
  const getVenusNameList = () => {
    apiService(`venue/list`, {}, 'post').then((result) => {
      if (result && result.data && result.data.venues) {
        let data = []
        result.data.venues.forEach(element => {
          data.push(element)
        });
        const val = {
          "id": 0,
          "name": 'Add Venue',
          "location": '',
          "pincode": '',
          "mapLink": '',
        }
        data.push(val)
        setEventVenueList(data)

      } else {
        let data = []
        const val = {
          "id": 0,
          "name": 'Add Venue',
          "location": '',
          "pincode": '',
          "mapLink": '',
        }
        data.push(val)
        setEventVenueList(data)
      }
    }).catch((err) => {

    });
  }
  const handleSelectEventVenue = (value) => {
    var data = eventVenueList.filter((list) => list.name === value)

    setValue('venueId', data[0].name)
    clearErrors('venueId')
    setValue('location', data[0].location)
    clearErrors('location')
    setValue('pincode', data[0].pincode)
    clearErrors('pincode')
    setValue('mapLink', data[0].mapLink)
    clearErrors('mapLink')
  }
  const [participantRoleList, setParticipantRoleList] = useState([])
  const getParsonaList = () => {
    axios.get('/json/profiledetails.json', '').then((result) => {
      if (result.data) {
        setParticipantRoleList(result.data)
      }
    }).catch((err) => {

    });
  }

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [mapOpen, setMapOpen] = useState(false)
  const handleModalOpen = () => {
    setMapOpen(true)
  }
  const handleModalClose = () => {
    setMapOpen(false)
  }

  const onSubmit = (data, isPublish = false) => {
    debugger
    data.departmentId = depId;
    const today = dayjs();
    const isStartDateValid = data.startDate && (data.startDate.isSame(today, 'day') || data.startDate.isAfter(today));
    const isEndDateValid = data.endDate && (data.endDate.isSame(today, 'day') || data.endDate.isAfter(data.startDate));
    debugger
    if (data.managementTeam) {
      data.managementTeam.forEach(ele => {
        if (ele.email) {
          ele.email = cryptoJS(ele.email.toLowerCase());
          console.log(ele.email, 'email')
        }
      });
    }
    if (data.startDate) {
      data.startDate = data.startDate.format('YYYY-MM-DD');
    }
    if (data.endDate) {
      data.endDate = data.endDate.format('YYYY-MM-DD');
    }
    if (data.registrationEndDate) {
      data.registrationEndDate = data.registrationEndDate.format('YYYY-MM-DD');
    }
    if (data.startTime) {
      data.startTime = data.startTime.format('HH:mm');
    }
    if (data.endTime) {
      data.endTime = data.endTime.format('HH:mm');
    }
    if (data.registrationEndDate && data.endDate && dayjs(data.registrationEndDate).isAfter(dayjs(data.endDate))) {
      notifyService('danger', 'Registration end date cannot be greater than the event end date.');
      return; // Prevent further execution
    }
    var cateValue = eventCategoryList.filter((list) => list.category === data.category)
    data.categoryId = cateValue[0].id
    debugger
    data.paid = data.paid === 'paid' ? true : false
    data.publish = publishBtn

    console.log(data)
    if (selectedDate === true) {
      notifyService("danger", "Please select a Valid Date");
      return
    }
    if (data.mapLink) {
      data.mapLink = data.mapLink.replace(/ width="[^"]*"/g, "").replace(/ height="[^"]*"/g, "");
    }

    setPageLoading(true);
    apiService('event/save', data, 'post').then((result) => {
      setPageLoading(false);
      if (result?.data?.id) {
        setPublishId(result.data.id)
      }
      if (result?.data?.responseStatus === "Success") {
        // handlDiscard();
        // supportistApi()
        if (isPublish === true) {
          publishEvent();
        }
        getApi(result.data.id)
      }
    }).catch((err) => {
      console.log(err);

    });
  }
  const handlePublish = (e) => {
    e.preventDefault();
    const data = getValues();
    debugger
    onSubmit(data, true);
  };

  const publishEvent = () => {
    const req = { id: publishId, type: "event" };
    setPageLoading(true);
    apiService("publish", req, "post")
      .then((res) => {
        setPageLoading(false);
        if (res?.data?.id) {
          setPublishId(res.data.id)
        }
        if (res?.data?.responseStatus === "Success") {
          handlDiscard();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const onError = (event) => {
    console.log("error Data:", event);
    notifyService(
      "danger",
      "Submit Failed",
      "Please check the mandatory fields"
    );
  };
  const handlDiscard = () => {
    reset()
    navigator('admin/event/list')
  }
  // const backBtn = () => {
  //   reset()
  //   navigator('admin/event/list')
  // }

  const [content, setContent] = useState("");
  const handleContentChange = (value) => {
    setContent(value);
    setValue("description", value, { shouldValidate: true });
  };
  return (
    <main className='event_add_section'>
      <div className='event_add_header mb-4'>
        <div className='d-flex w-100 align-items-center'>
          <div>
            <p className="back-btns mb-0" onClick={handlDiscard}>
              <FaArrowLeft />
              <span style={{ marginLeft: "5px" }}>Back</span>
            </p>
          </div>
          {eventId ? (
            <div className="m-auto">
              <h4 className="font-color-black">{formData.title}</h4>
            </div>
          ) : (
            <div className="m-auto">
              <h4 className="font-color-black"> Create Event</h4>
            </div>
          )}
        </div>
      </div>
      <p className='font-color-black'>Event Details</p>
      <div className=''>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className='event_add_details_div mb-4'>
            <Grid container spacing={3}>
              <Grid item lg={3} md={3} sm={12} xs={12} className="mb-3">
                <div className='input_background'>
                  <Controller
                    name="mode"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Event Mode is required' }}
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel> Choose Event Mode *</FormLabel>
                        <RadioGroup row  {...field} onChange={(e) => handleEventMode(e.target.value)}>
                          <FormControlLabel value={'Online'} control={<Radio />} label="Online" />
                          <FormControlLabel value={'Offline'} control={<Radio />} label="Offline" />
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.mode && errors.mode.message}
                  </FormHelperText>
                </div>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12} className="mb-3">
                <div className='input_background'>
                  <Controller
                    name="paid"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Fee Status is required' }}
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel> Registration Fee *</FormLabel>
                        <RadioGroup row  {...field} onChange={(e) => handleFeeMode(e.target.value)}>
                          <FormControlLabel value={'free'} control={<Radio />} label="Free" />
                          <FormControlLabel value={'paid'} control={<Radio />} label="Paid" />
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.paid && errors.paid.message}
                  </FormHelperText>
                </div>
              </Grid>
              {
                getValues('paid') === 'paid' ? (
                  <Grid item lg={4} md={4} sm={12} xs={12} className="mb-3">
                    <div className='input_background mb-4'>
                      <Controller
                        name="amount"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Registration amount is required" ,validate: (value) =>
                          value > 0 || "Registration amount must be greater than 0"}}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Registration Amount *"
                            placeholder="Enter Registration Amount"
                            fullWidth
                            type="text"
                            {...field}
                            onKeyDown={(e) => validateNumberonly(e)}
                            inputProps={{ maxLength: 8 }}
                          />
                        )}
                      />
                      <FormHelperText>
                        <FaInfoCircle style={{ color: 'darkgray' }} /> Please note: We do not collect any payments for paid events, kindly include payment details in the event description box provided.
                      </FormHelperText>
                      <FormHelperText className="text-danger">
                        {errors.amount && errors.amount.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                ) : (
                  <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3"></Grid>
                )
              }

              {/* <Grid item lg={8} md={8} sm={12} xs={12} className="mb-3">
                <div className='input_background'>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Event type is required' }}
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel> Choose Event type </FormLabel>
                        <RadioGroup row  {...field} >
                          <FormControlLabel value={'Startup / Enablers Event'} control={<Radio />} label="Startup / Enablers Event" />
                          <FormControlLabel value={'Team Event'} control={<Radio />} label="Team Event (eg. Internal Team Meetings  / Commitee meeting) " />
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.type && errors.type.message}
                  </FormHelperText>
                </div>
              </Grid> */}
              <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3">
                <div className='input_background mb-4'>
                  <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Event Title is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Event Title *"
                        placeholder="Please Enter Event Title"
                        fullWidth
                        type="text"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.title && errors.title.message}
                  </FormHelperText>
                </div>
                {/* <div className='input_background mb-4'>
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Event Description is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Event Description *"
                        placeholder="Please Enter Event Description"
                        fullWidth
                        type="text"
                        {...field}
                        multiline
                        rows={4}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.description && errors.description.message}
                  </FormHelperText>
                </div> */}
                <div className="input_background5">
                  {
                    getValues('imageName') ? (
                      <>
                        <label >Upload Event Banner<span className="text-danger ml-1">*</span></label>
                        <div className="row">
                          <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3 text-center">
                            <Controller
                              name="imageName"
                              control={control}
                              defaultValue=""
                              rules={{ required: "Event Banner is required" }}
                              render={({ field }) => (
                                <Button
                                  component="label"
                                  variant="contained"
                                  color="success"
                                  {...field}
                                  sx={{ width: '90%', fontSize: '11px', textTransform: 'none', padding: '8px 0px' }}
                                  onChange={(e) =>
                                    handleFileUpload(e, "image", "imageName", "imageUrl", 'image')
                                  }
                                  startIcon={<CloudDoneIcon />}
                                  href="#file-upload"
                                >
                                  Uploaded ( Click to Change)
                                  <VisuallyHiddenInput type="file" />
                                </Button>
                              )}
                            />
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <Button
                              className="c-pointer ml-2"
                              size="medium"
                              sx={{ width: '90%' }}
                              variant="outlined"
                              onClick={() =>
                                handleClickOpen(
                                  getValues("image"),
                                  getValues("imageUrl"),
                                )
                              }
                            >
                              Preview
                            </Button>
                          </Grid>
                        </div>
                        <div>
                          <span className="fn-12   mt-1 ">
                            Maximum 5 mb allowed doc (Accepted Format: jpeg, jpg, png)
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="form-group mt-2">
                        <Controller
                          name="imageName"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Event Banner is required" }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Upload Event Banner *"
                              placeholder="Upload Image "
                              fullWidth
                              type="text"
                              {...field}
                              disabled
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.imageName && errors.imageName.message}
                        </FormHelperText>
                        <div className='d-flex align-items-center w-100 my-3'>
                          <p className='font-sm'>
                            Recommended Pixel ( 1920 X 1080) <br />
                            Max size upto 5mb <br />
                            Accepted Format: jpeg, jpg, png</p>
                          <label className='ml-auto lbl_btn1'
                            onChange={(e) => { handleFileUpload(e, "image", "imageName", "imageUrl", 'image') }}
                            href="#file-upload">
                            <img src='/images/admin/upload.png' className='mr-3' alt='' />
                            Upload
                            <VisuallyHiddenInput type="file" />
                          </label>
                        </div>
                      </div>
                    )
                  }
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                <p>Date & Time</p>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                    {/* <div className='input_background'>
                      <Controller
                        name={`startDate`}
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} fullWidth>
                              <DatePicker label="Start Date" format="DD/MM/YYYY"
                                minDate={today}
                                value={startdate}
                                {...field}
                                sx={{ width: "100%" }}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => handleStartDateFormat(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.startDate &&
                          errors.startDate.message}
                      </FormHelperText>
                    </div> */}
                    <div className='input_background'>
                      <Controller
                        name="startDate"
                        control={control}
                        rules={{ required: "Start Date is Required" }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Start Date *"
                              format="DD/MM/YYYY"
                              disablePast
                              value={startDate}
                              {...field}
                              sx={{ width: '100%' }}
                              onChange={(newValue) => {
                                handleStartDateChange(newValue);
                                field.onChange(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.startDate && errors.startDate.message}
                      </FormHelperText>
                    </div>

                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                    {/* <div className='input_background'>
                      <Controller
                        name={`endDate`}
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} fullWidth>
                              <DatePicker label="End Date" format="DD/MM/YYYY"
                                minDate={startdate ? startdate : today}
                                value={enddate}
                                {...field}
                                sx={{ width: "100%" }}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => handleEndDateFormat(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.endDate &&
                          errors.endDate.message}
                      </FormHelperText>
                    </div> */}
                    <div className='input_background'>
                      <Controller
                        name="endDate"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="End Date"
                              format="DD/MM/YYYY"
                              disablePast
                              minDate={startDate || today}
                              value={endDate}
                              {...field}
                              sx={{ width: '100%' }}
                              onChange={(newValue) => {
                                handleEndDateChange(newValue);
                                field.onChange(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.endDate && errors.endDate.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                    <div className='input_background'>
                      <Controller
                        name={`startTime`}
                        control={control}
                        rules={{ required: "Start Time is Required" }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']} fullWidth>
                              <TimePicker label="Start Time *" format="HH:mm"
                                value={startTime}
                                {...field}
                                sx={{ width: "100%" }}
                                inputFormat="HH:mm"
                                onChange={(newValue) => handleStartTimeFormat(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.startTime &&
                          errors.startTime.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                    <div className='input_background'>
                      <Controller
                        name={`endTime`}
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']} fullWidth>
                              <TimePicker label="End Time " format="HH:mm"
                                value={endTime}
                                {...field}
                                sx={{ width: "100%" }}
                                inputFormat="HH:mm"
                                onChange={(newValue) => handleEndTimeFormat(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.endTime &&
                          errors.endTime.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                    {/* <div className='input_background'>
                      <Controller
                        name={`registrationEndDate`}
                        control={control}
                        rules={{ required: 'Registration End Date is required' }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} fullWidth>
                              <DatePicker label="Registration End Date *" format="DD/MM/YYYY"
                              disablePast={true}
                                value={regdate}
                                {...field}
                                InputProps={{
                                  readOnly: true,
                                }}
                                sx={{ width: "100%" }}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => handleRegDateFormat(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.registrationEndDate && errors.registrationEndDate.message}
                      </FormHelperText>
                    </div> */}
                    <div className='input_background'>
                      <Controller
                        name="registrationEndDate"
                        control={control}
                        rules={{ required: "Registration End Date is Required" }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Registration End Date *"
                              format="DD/MM/YYYY"
                              disablePast
                              minDate={today}
                              maxDate={endDate || null}
                              value={registrationDate || endDate}
                              {...field}
                              sx={{ width: '100%' }}
                              onChange={(newValue) => {
                                handleRegistrationDateChange(newValue);
                                field.onChange(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.registrationEndDate && errors.registrationEndDate.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                <Grid container spacing={1}>
                  <Grid item lg={4} md={4} sm={12} xs={12} className="mb-3">
                    <div className='input_background'>
                      <Controller
                        name="category"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Event Category is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            options={eventCategoryList.map((name) => name.category)}
                            onChange={(e, selectedOptions) =>
                              handleSelectEventCategory(selectedOptions)
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Choose Event Category *" />
                            )}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.category && errors.category.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <div className="input_background">
                      <Controller
                        name="redirectUrl"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Redirect URL is required ",
                          pattern: {
                            value: urlPattern,
                            message: "Invalid URL format"
                          }
                        }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Redirect URL *"
                            placeholder="Enter Redirect URL"
                            fullWidth
                            type="text"
                            {...field}
                          // inputProps={{ readOnly: readyOnlyValue }}
                          // error={Boolean(errors.url && errors.url)}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.redirectUrl && errors.redirectUrl.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
              getValues('mode') !== '' && (
                <>
                  {getValues('mode') !== 'Online' && (<p className='font-color-gray'>Venue details</p>)}
                  <Grid container spacing={3}>
                    {
                      getValues('mode') === 'Online' ? (
                        <>
                          <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3">
                            <p className='font-color-gray'>Online Meeting Details</p>
                            <div className='input_background'>
                              <Controller
                                name="meetLink"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "Zoom / Other Meet Link  is required ",
                                  validate: (value) =>
                                    value.trim() !== "" || "Zoom cannot contain only spaces"
                                }}
                                render={({ field }) => (
                                  <TextField
                                    variant="outlined"
                                    label="Zoom / Other Meet Link *"
                                    placeholder="Enter meeting Link"
                                    fullWidth
                                    type="text"
                                    {...field}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.meetLink && errors.meetLink.message}
                              </FormHelperText>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <>
                          {/* <Grid item lg={3} md={3} sm={12} xs={12} className="mb-3">
                            <div className='input_background'>
                              <Controller
                                name="venueId"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Venue is required" }}
                                render={({ field }) => (
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    {...field}
                                    options={eventVenueList.map((val => val.name ? val.name : []))}
                                    onChange={(e, selectedOptions) =>
                                      handleSelectEventVenue(selectedOptions)
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} label="Venue *" />
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.venueId && errors.venueId.message}
                              </FormHelperText>
                            </div>
                          </Grid>
                          {
                            getValues('venueId') === 'Add Venue' && (
                              <Grid item lg={3} md={3} sm={12} xs={12} className="mb-3">
                                <div className='input_background'>
                                  <Controller
                                    name="venue"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Venue is required" }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Venue Name *"
                                        placeholder="Enter Venue Name"
                                        fullWidth
                                        type="text"
                                        {...field}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.venue && errors.venue.message}
                                  </FormHelperText>
                                </div>
                              </Grid>
                            )
                          } */}

                          <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <Grid container spacing={1}>
                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <div className='input_background'>
                                  <Controller
                                    name="venue"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Venue is required" }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Venue *"
                                        placeholder="Enter Venue Details"
                                        fullWidth
                                        type="text"
                                        {...field}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.venue && errors.venue.message}
                                  </FormHelperText>
                                </div>
                              </Grid>
                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <div className='input_background'>
                                  <Controller
                                    name="district"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "District is required" }}
                                    render={({ field }) => (
                                      <Autocomplete
                                        {...field}
                                        disablePortal
                                        options={district.map((option) => option.name)}
                                        onChange={(event, newValue) => handleSelectDistrict(newValue)}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="District *"
                                            placeholder="Select District"
                                          />
                                        )}
                                        fullWidth
                                        margin="normal"
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.district && errors.district.message}
                                  </FormHelperText>
                                </div>
                              </Grid>
                              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                                <div className='input_background'>
                                  <Controller
                                    name="pincode"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Pincode is required" }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Pincode *"
                                        placeholder="Enter Pincode "
                                        fullWidth
                                        type="text"
                                        {...field}
                                        onKeyDown={(e) => validateNumberonly(e)}
                                        inputProps={{ maxLength: 6, minLength: 6 }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.pincode && errors.pincode.message}
                                  </FormHelperText>
                                </div>
                              </Grid> */}
                            </Grid>
                          </Grid>
                          <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3">
                            <div className='input_background'>
                              <Controller
                                name="mapLink"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Map Link is required" }}
                                render={({ field }) => (
                                  <TextField
                                    variant="outlined"
                                    label="Map Link *"
                                    placeholder="Enter Map Destination link"
                                    fullWidth
                                    type="text"
                                    {...field}
                                  />
                                )}
                              />
                              <FormHelperText onClick={() => handleModalOpen()} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                                Click here to get a googlemap link
                              </FormHelperText>
                              <FormHelperText className="text-danger">
                                {errors.mapLink && errors.mapLink.message}
                              </FormHelperText>
                            </div>
                          </Grid>
                          {/* {
                            getValues('venueId') === 'Add Venue' && (
                              <Grid item lg={4} md={6} sm={6} xs={12}>
                                <div className='input_background mb-4'>
                                  <Controller
                                    name="saveAddress"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                      <FormControl>
                                        <FormGroup row  {...field}>
                                          <FormControlLabel control={<Switch />} label="Save to Address Book" />
                                        </FormGroup>
                                      </FormControl>
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.saveAddress && errors.saveAddress.message}
                                  </FormHelperText>
                                </div>
                              </Grid>
                            )
                          } */}
                        </>
                      )
                    }

                  </Grid>
                </>
              )
            }
            <Grid container spacing={2}>
              <Grid item lg={8} md={8} sm={12} xs={12} className="mb-3">
                <div className='input_background'>
                  <FormLabel shrink="true" className="input_Lable">
                    Event Description <span className="text-danger">*</span>{" "}
                  </FormLabel>
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Event Description is required", validate: (value) => value.trim() !== "" || "Event Description is Required", }}
                    render={({ field }) => (

                      <ReactQuill
                        theme="snow"
                        value={content}
                        modules={toolbarOptions}
                        formats={formats}
                        onChange={(content, delta, source, editor) => {
                          handleContentChange(content);
                          field.onChange(content);
                        }}
                        placeholder="Enter Event Description"
                        fullWidth
                        type="text"
                        {...field}
                        multiline
                      />
                    )}
                  />

                  <FormHelperText className="text-danger">
                    {errors.description &&
                      errors.description.message}
                  </FormHelperText>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="text-center">
            <Button className="discard-btn" onClick={() => handlDiscard()}>
              Discard
            </Button>
            <Button type="submit" className="publish-button">
              {publishBtn === false ? "Save as Draft" : "Save & Publish"}
            </Button>
            {(publishBtn === false && publishId !== 0) && (<Button className="publish-button-draft" onClick={(e) => handlePublish(e)}>
              Publish
            </Button>)}
          </div>
        </form>
      </div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={mapOpen}
        onClose={handleModalClose}
      >

        <DialogContent sx={{ padding: '30px 50px' }}>
          <div>

            <h4>Step to get a google map link</h4>
            <ul>
              <li className='mb-2'>Open Google Map & Select your location.</li>
              <li className='mb-2'>Click a share button & select 'Embed a map'.</li>
              <li>Click a 'COPY HTML' & Paste your Google Map Link to Maplink.</li>
            </ul>
            <div className='text-center'>
              <span
                style={{ color: "#2260FF", cursor: "pointer" }}
                onClick={handleModalClose}
              >
                Back
              </span>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Viewimage
        viewImage={viewImage}
        viewImageName={viewImageName}
        open={open}
        handleClose={handleClose}
      />
    </main>
  )
}

export default AdminEventAdd