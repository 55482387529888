import React, { useContext, useState } from "react";
import { Button, CardActions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Pagination, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";

const ProjectList = () => {
  const { navigator, setPageLoading, hasPrivilege } = useContext(DataContext);
  const [page, setPage] = useState(1);
  let depId = localStorage.getItem('departmentId')
  useDidMountEffect(() => {
    listApi()
  }, [page])

  const [totalPage, setTotalPage] = useState(1);
  const [meetingList, setMeetingList] = useState([]);

  const listApi = () => {
    setMeetingList([])
    setPageLoading(true)
    var data = {
      listSize: 12,
      pageNumber: page,
      departmentId: depId
    };
    apiService("nodal/project/list", data, "post")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          setTotalPage(result.data.totalPages);
          if (result.data.projects) {
            setMeetingList(result.data.projects);
          }

        }
      })
      .catch((err) => {
        console.log(err);
        
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleAddProject = () => {
    navigator("/admin/project/add");
  };

  const handleDetailsView = (id) => {
    navigator(`/admin/project/add/${btoa(id)}`);

  };
  const [openModal, setOpenModal] = useState(false);
  const [userId, setUserId] = useState([]);
  const handleOpenModal = (id) => {
    setOpenModal(true);
    setUserId(id);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleDeleteEventDetails = () => {
    setPageLoading(true)
    apiService(`project/remove?id=${userId}`, "", "get").then((result) => {
      setPageLoading(false)
      if (result && result.data && result.data.responseStatus === "Success") {
        handleCloseModal();
        listApi();
      }
    }).catch((err) => {
      console.log(err)
    });
  }

  return (
    <div className="department-add-crd mt-3">
      <div className="depCrd-title mb-3">
        <div className="text-dep">
          <h6>Projects</h6>
          <span>See your Project information</span>
        </div>
        <div className="dep-add-act">
          {
            hasPrivilege("47") && (
              <button
                className="dep-add-act-btn mr-5"
                onClick={() => handleAddProject()}
              >
                + Add Project
              </button>
            )
          }
        </div>
      </div>
      <div className="added-card">
        <div className="">
          {meetingList.length > 0 ? (
            <div className="">
              <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={4}>
                {
                  meetingList.map((item, i) => (
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <div className="card_sec1">
                        <Card className="card-comp-adm" key={i}>
                          <div className="imag-border">
                            <img
                              className="comp-logo"
                              src={item.imageName ? item.imageName : '/images/oneTn/onetn_fav.png'}
                              alt="Project-logo"
                            />
                          </div>
                          <CardContent>
                            <p className="admcrd-para-color">{item.name}</p>
                          </CardContent>
                          <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="view det ">
                              <button
                                className="depart-view-btn"
                                onClick={() => handleDetailsView(item.id)}
                              >
                                View Details
                              </button>

                            </div>
                            <div>
                              <button className="ml-3 admin-deletebtn" onClick={() => handleOpenModal(item.id)}>Delete</button>
                            </div>
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>
                  ))
                }
              </Grid>
            </div>
          ) : (
            <div className='text-center nodatacss_admin'>
              <h5 className='mb-4'>Project data not found</h5>
              <div>
                <img src="/images/oneTn/black_oneTn.png" alt="blue horizontal.png" className='img-fluid' />
              </div>
            </div>
          )
          }
          {
            meetingList.length > 0 && totalPage > 1 && (
              <div className="event-pagination">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChange}
                  />
                </Stack>
              </div>
            )
          }
        </div>
      </div>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth={"sm"}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <center>
            <img src="/images/Banner/attention.gif" alt="" className="alert-image" />
          </center>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h4 className="text-center p-3">Are you sure ?</h4>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mb-2">
          <Button
            className="remove-api-modal-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button className="remove-api-modal-confirm" onClick={() => handleDeleteEventDetails()}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

export default ProjectList;