import React, { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, Container, Dialog, Paper, DialogContent, DialogContentText, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Menu, DialogTitle, DialogActions } from "@mui/material";
import { Grid } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import Norecordsfound from "../../common-pages/Norecordsfound";
import Eventregister from "../../Media-module/Media-layout-page/Event-Page/Eventregister";
import AdminEventFilter from "./AdminEventFilter";
import CustomCalendar from "../../common-pages/CustomCalendar";


const AdminEventList = () => {
  const { navigator, fullScreen, CustomTabPanel, setPageLoading, startDate, setStartDate, endDate, setEndDate, hasPrivilege } = useContext(DataContext);
  useDidMountEffect(() => {
    setStartDate('')
    setEndDate('')
  }, [])
  const role = localStorage.getItem("role");
  const DepsIds = localStorage.getItem("departmentId");
  const [totalPage, setTotalPage] = useState(1);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEventIndex, setSelectedEventIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [eventsData, setEventsData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("All")
  const [selectedType, setSelectedType] = useState("myevent")
  const [sortBy, setSortBy] = useState(true)
  const [activeView, setActiveView] = useState(0);
  const [departmentId, setDepartmentId] = useState();
  useDidMountEffect(() => {
    listApi();
  }, [searchInputValue, page, selectedPeriod, selectedType, startDate, endDate, sortBy, departmentId, activeView]);

  const listApi = () => {
    setPageLoading(true)
    setEventsData([])
    var data = {
      listSize: 10,
      pageNumber: page,
      searchString: searchInputValue,
      period: selectedPeriod,
      type: selectedType,
      startDate: startDate ? (startDate + 'T00:00:00.174Z') : '',
      endDate: endDate ? (endDate + 'T00:00:00.174Z') : '',
      sort: sortBy ? 'asc' : 'desc',
      departmentId: Number(DepsIds),
      view: activeView
    };
    apiService("event/list", data, "post")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          setTotalPage(result.data.totalPages);
          if (activeView === 0) {
            if (result.data.responseModelList) {
              setEventsData(result.data.responseModelList);
            }
          }
          if (activeView === 1) {
            if (result.data.calendarViewList) {
              setEventsData(result.data.calendarViewList);
            }
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
      });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };


  useDidMountEffect(() => {
    if (eventsData.length > 0) {
      setSelectedEvent(eventsData[selectedEventIndex]);
    } else {
      setSelectedEvent(null)
      setSelectedEventIndex(0)
    }
  }, [eventsData]);
  const handleEventClick = (event, index) => {
    setSelectedEvent(event);
    setSelectedEventIndex(index);
  };




  const [opens, setOpens] = useState(false);
  const [eventId, setEventId] = useState(0);
  const handleViewEventDetails = (val) => {
    // setEventId(val);
    // setOpens(true);
    var location = window.location.pathname
    localStorage.setItem("EventLocation", location)
    navigator(`/admin/eventdetails/${btoa(val)}`)
  };
  const handleEventClose = () => {
    setOpens(false);
  };
  const handleEditEventDetails = (id) => {
    navigator(`/admin/event/${btoa(id)}`)
  }
  const [openModal, setOpenModal] = useState(false);
  const [eveId, setEveId] = useState([]);
  const handleOpenModal = (id) => {
    setOpenModal(true);
    setEveId(id);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleDeleteEventDetails = () => {
    apiService(`event/remove?id=${eveId}`, '', "delete").then((result) => {
      if (result && result.data && result.data.responseStatus === "Success") {
        handleCloseModal()
        listApi();
      }
    }).catch((err) => {
      console.log(err);
      
    });
  }
  return (
    <div className="admin-event-page">
      <AdminEventFilter
        setSearchInputValue={setSearchInputValue}
        setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod}
        setSelectedType={setSelectedType} selectedType={selectedType}
        setSortBy={setSortBy} sortBy={sortBy}
        setDepartmentId={setDepartmentId}
        setActiveView={setActiveView} activeView={activeView}
        setPage={setPage}
        name={'list'}
      />
      <div className="event-datas-list py-5">
        <CustomTabPanel value={activeView} index={0}>
          {eventsData.length > 0 ? (
            <>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="center"
                spacing={5}
              >
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  {eventsData.length > 0 &&
                    <div className="table_main_div">
                      <TableContainer component={Paper}>
                        <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                          <TableBody sx={{ position: 'relative' }}>
                            {eventsData.map((list, index) => (
                              <>
                                <TableRow
                                  sx={{
                                    position: 'relative',
                                    '&:after': {
                                      content: '""',
                                      position: 'absolute',
                                      top: 0,
                                      bottom: 0,
                                      left: 0,
                                      right: 0,
                                      border: '1px solid rgba(224, 224, 224, 1)',
                                      borderRadius: '20px',
                                      pointerEvents: 'none',
                                      marginBottom: '-1px',
                                    },
                                    '& > *': {
                                      padding: '10px',
                                      backgroundColor: '#fff',
                                      '&:last-of-type': { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' },
                                    },
                                    marginBottom: '10px',
                                  }}
                                  key={index}
                                  className={selectedEventIndex === index ? "Sup-list selected" : "Sup-list"}
                                  onClick={() => handleEventClick(list, index)}
                                >
                                  <TableCell>
                                    <div className="px-3">
                                      <Typography variant="h6" className="list-2-clr">{list.title}</Typography>
                                      <div className="list-2-dflex">
                                        <span className="list-2-grey">Category:</span>
                                        <span className="list-2-black">{list.category}</span>
                                      </div>
                                      <div>
                                        <span className="list-2-black" style={{ color: list.publish === true ? '#00AA00' : "orangered", fontWeight: '500' }}>{list.publish === true ? "Published" : "Not Published"}</span>
                                      </div>
                                      <div>
                                        {
                                          hasPrivilege('5') ? (
                                            <>
                                              {/* <span className="list-2-grey">Department : </span>
                                              <span className="list-2-black">{list.departmentName}</span> */}
                                            </>
                                          ) : (
                                            <>
                                              <span className="list-2-grey">Event Manager:</span>
                                              <span className="list-2-black">{list.eventManager}</span>
                                            </>
                                          )
                                        }

                                        {/* <span className="list-2-grey">Department : </span>
                                        <span className="list-2-black">StartupTN</span> */}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="events-date-box">
                                      {
                                        list.startDay && list.startMonth ? (
                                          <div className="date-blue-box1-eve mr-2">
                                            <p>{list.startDay}</p>
                                            <p>{list.startMonth}</p>
                                          </div>
                                        ) : (<></>)
                                      }
                                      {
                                        list.endDay && list.endDay && list.endMonth ? (
                                          <div className="date-blue-box1-eve">
                                            <p>{list.endDay}</p>
                                            <p>{list.endMonth}</p>
                                          </div>
                                        ) : (<></>)
                                      }
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="events-time-box mr-3">
                                      <div className="time-gray-box">
                                        {
                                          list.time ? (
                                            <p>
                                              <span>
                                                <img
                                                  src="/images/clock-check.png"
                                                  alt="Time"
                                                  className="admin-time-icn"
                                                />
                                              </span>
                                              {list.time}
                                            </p>
                                          ) : (<></>)
                                        }
                                        {
                                          list.location && (
                                            <p>
                                              <span>
                                                <img
                                                  src="/images/location_svg.png"
                                                  alt="Location"
                                                  className="admin-location-icn"
                                                />
                                              </span>
                                              {list.location}
                                            </p>
                                          )
                                        }

                                      </div>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                        {totalPage > 1 && (
                          <div className="event-pagination">
                            <Stack spacing={2}>
                              <Pagination
                                count={totalPage}
                                page={page}
                                onChange={handleChange}
                              />
                            </Stack>
                          </div>
                        )}
                      </TableContainer>
                    </div>
                  }
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  {selectedEvent && (
                    <Card sx={{ maxWidth: 385 }} className="card-event-list">
                      {/* <CardMedia
                        component="img"
                        sx={{ height: "198px", width: "100%" }}
                        // image={selectedEvent.imageName}
                        image={selectedEvent.imageName ? selectedEvent.imageName : '/images/oneTn/white-image.png'}
                        alt="green iguana"
                      /> */}
                      <img src={selectedEvent.imageName ? selectedEvent.imageName : '/images/oneTn/white-image.png'} alt="green iguana" style={{ width: '100%' }} />
                      <div className="crd-lists">
                        <div className="card-event-det">
                          <h6>{selectedEvent.title}</h6>
                          <p>
                            Category: <span>{selectedEvent.category}</span>
                          </p>
                          {
                            hasPrivilege('5') ? (
                              <><p>
                                {/* Department : <span>{selectedEvent.departmentName}</span> */}
                              </p></>
                            ) : (
                              <>
                                <p>
                                  Event Manager : <span>{selectedEvent.eventManager}</span>
                                </p>
                                <p>
                                  Event Coordination :{" "}
                                  <span>{selectedEvent.formattedEventCoordinators ? selectedEvent.formattedEventCoordinators : '-'}</span>
                                </p>
                              </>
                            )
                          }
                          {selectedEvent.venue ? (<p>
                            Venue: <span>{selectedEvent.venue},{selectedEvent.district}</span>
                          </p>) : (<span className="event-location">
                            <img
                              src="/images/online-symbal.png"
                              alt=""
                              className="mr-2"
                            />
                            {selectedEvent.mode}
                          </span>)}
                          {/* <p>
                            Key Investors : <span>Thyrocare Velumani, Anicut Capital Team</span>
                          </p> */}
                          {/* {
                            hasPrivilege('6') && (
                              <p>
                                Special Participants:{" "}
                                <span>{selectedEvent.formattedSpecialParticipant ? selectedEvent.formattedSpecialParticipant : '-'}</span>
                              </p>
                            )
                          } */}
                        </div>
                      </div>
                      <div className="admin-crd-actions">
                        <button className="admin-viewbtn" onClick={() => handleViewEventDetails(selectedEvent.id)}>View</button>
                        {
                          hasPrivilege('3') && selectedType === 'myevent' && (
                            <button className="ml-3 admin-editbtn " onClick={() => handleEditEventDetails(selectedEvent.id)}>Edit</button>
                          )
                        }
                        {
                          hasPrivilege('4') && selectedType === 'myevent' && (
                            <button className="ml-3 admin-deletebtn" onClick={() => handleOpenModal(selectedEvent.id)}>Delete</button>
                          )
                        }
                      </div>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </>
          )
            : (
              <>
                <div className='text-center nodatacss_admin'>
                  <h5 className='mb-4'>Events data not found</h5>
                  <div>
                    <img src="/images/blue horizontal.png" alt="blue horizontal.png" className='logo_dash img-fluid' />
                  </div>
                  <div className='mt-5'>
                    <h3 className='title_p opa'>
                      {/* StartupTN 3.0 */}
                      OneTN
                    </h3>
                  </div>
                </div>
              </>
            )
          }
        </CustomTabPanel>
        <CustomTabPanel value={activeView} index={1}>
          <CustomCalendar value={eventsData} name={'Events'} handleViewEventDetails={handleViewEventDetails} />
        </CustomTabPanel>
      </div>

      <Dialog fullScreen={fullScreen} maxWidth={"lg"} open={opens}>
        <DialogContent className="dialog-modal">
          <DialogContentText id="alert-dialog-description">
            <div>
              <span
                style={{ color: "#2260FF", cursor: "pointer" }}
                onClick={handleEventClose}
              >
                <ArrowBackIcon /> Back
              </span>
            </div>
            <Eventregister categoryId={eventId} setOpens={setOpens} via={'admin'} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth={"sm"}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <center>
            <img src="/images/Banner/attention.gif" alt="" className="alert-image" />
          </center>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h4 className="text-center p-3">Are you sure ?</h4>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mb-2">
          <Button
            className="remove-api-modal-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button className="remove-api-modal-confirm" onClick={() => handleDeleteEventDetails()}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

export default AdminEventList;