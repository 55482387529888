import React, { useState, useContext } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {
  Dialog,
  Paper,
  DialogContent,
  DialogContentText,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import LatestNewsFilter from "./LatestNewsFilter";

const LatestNewsList = () => {
  const {
    navigator,
    fullScreen,
    setPageLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    hasPrivilege,
  } = useContext(DataContext);
  useDidMountEffect(() => {
    setStartDate("");
    setEndDate("");
  }, []);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedLatestnews, setSelectedLatestnews] = useState(null);
  const [selectedLatestnewsIndex, setSelectedLatestnewsIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [latestnewsData, setLatestnewsData] = useState([]);
  let departmentId = localStorage.getItem("departmentId");
  useDidMountEffect(() => {
    listApi();
  }, [searchInputValue, page, startDate, endDate]);

  const listApi = () => {
    setPageLoading(true);
    setLatestnewsData([]);
    var data = {
      listSize: 5,
      pageNumber: page,
      departmentId: departmentId,
      searchString: searchInputValue,
      fromDate: startDate ? startDate : "",
      toDate: endDate ? endDate : "",
    };
    apiService("news/list", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result?.data) {
          setTotalPage(result.data.totalPages);
          if (result?.data?.news) {
            setLatestnewsData(result.data.news);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching Blogs:", err);
      });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  useDidMountEffect(() => {
    if (latestnewsData.length > 0) {
      setSelectedLatestnews(latestnewsData[selectedLatestnewsIndex]);
    } else {
      setSelectedLatestnews(null);
      setSelectedLatestnewsIndex(0);
    }
  }, [latestnewsData]);
  const handleLatestnewsClick = (event, index) => {
    setSelectedLatestnews(event);
    setSelectedLatestnewsIndex(index);
  };

  const [opens, setOpens] = useState(false);
  const handleViewLatestnewsDetails = () => {
    setOpens(true);
  };
  const handleLatestnewsClose = () => {
    setOpens(false);
  };
  const handleEditLatestnewsDetails = (id) => {
    navigator(`/admin/latestnews/${btoa(id)}`);
  };
  const [openModal, setOpenModal] = useState(false);
  const [newsId, setNewsId] = useState([]);
  const handleOpenModal = (id) => {
    setOpenModal(true);
    setNewsId(id);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleDeleteLatestnewsDetails = () => {
    apiService(`news/remove?id=${newsId}`, "", "delete")
      .then((result) => {
        if (result?.data?.responseStatus === "Success") {
          handleCloseModal()
          listApi();
        }
      })
      .catch((err) => {});
  };
  return (
    <div className="admin-event-page">
      <LatestNewsFilter setSearchInputValue={setSearchInputValue} setPage={setPage}/>
      <div className="event-datas-list py-5">
        {latestnewsData.length > 0 ? (
          <>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={5}
            >
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                {latestnewsData.length > 0 && (
                  <div className="table_main_div">
                    <TableContainer component={Paper}>
                      <Table
                        sx={{
                          borderCollapse: "separate",
                          borderSpacing: "0 10px",
                        }}
                      >
                        <TableBody sx={{ position: "relative" }}>
                          {latestnewsData.map((list, index) => (
                            <>
                              <TableRow
                                sx={{
                                  position: "relative",
                                  "&:after": {
                                    content: '""',
                                    position: "absolute",
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    borderRadius: "20px",
                                    pointerEvents: "none",
                                    marginBottom: "-1px",
                                  },
                                  "& > *": {
                                    padding: "10px",
                                    backgroundColor: "#fff",
                                    "&:last-of-type": {
                                      borderTopRightRadius: "20px",
                                      borderBottomRightRadius: "20px",
                                    },
                                  },
                                  marginBottom: "10px",
                                }}
                                key={index}
                                className={
                                  selectedLatestnewsIndex === index
                                    ? "Sup-list selected"
                                    : "Sup-list"
                                }
                                onClick={() =>
                                  handleLatestnewsClick(list, index)
                                }
                              >
                                <TableCell>
                                  <div className="px-3">
                                    <Typography
                                      variant="h6"
                                      className="list-2-clr"
                                    >
                                      {list.title}
                                    </Typography>
                                    <div className="list-2-dflex">
                                      <span className="list-2-grey">
                                        Source :{" "}
                                      </span>
                                      <span className="list-2-black">
                                        {list.source}
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="events-date-box">
                                    {list.createdTime && (
                                      <div className="date-blue-box mr-2">
                                        <p>{list.createdTime}</p>
                                      </div>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell>
                                <div>
                                <span className="list-2-black" style={{color:list.publish === true ? '#00AA00' : "orangered",fontWeight:'500'}}>{list.publish === true ? "Published":"Not Published"}</span>
                                  </div>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                      {totalPage > 1 && (
                        <div className="event-pagination">
                          <Stack spacing={2}>
                            <Pagination
                              count={totalPage}
                              page={page}
                              onChange={handleChange}
                            />
                          </Stack>
                        </div>
                      )}
                    </TableContainer>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                {selectedLatestnews && (
                  <Card sx={{ maxWidth: 385 }} className="card-event-list">
                    <img
                      src={
                        selectedLatestnews.fileName
                          ? selectedLatestnews.fileName
                          : "/images/oneTn/white-image.png"
                      }
                      alt="Latest_News _Images"
                      style={{ width: "100%" }}
                    />
                    <div className="crd-lists">
                      <div className="card-event-det">
                        <h6>{selectedLatestnews.title}</h6>
                        <p>
                          Source : <span>{selectedLatestnews.source}</span>
                        </p>
                      </div>
                    </div>
                    <div className="admin-crd-actions">
                      <button
                        className="admin-viewbtn"
                        onClick={() => handleViewLatestnewsDetails()}
                      >
                        View
                      </button>
                      {hasPrivilege("68") && (
                        <button
                          className="ml-3 admin-editbtn "
                          onClick={() =>
                            handleEditLatestnewsDetails(selectedLatestnews.id)
                          }
                        >
                          Edit
                        </button>
                      )}
                      {hasPrivilege("69") && (
                        <button
                          className="ml-3 admin-deletebtn"
                          onClick={() =>
                            handleOpenModal(selectedLatestnews.id)
                          }
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </Card>
                )}
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <div className="text-center nodatacss_admin">
              <h5 className="mb-4">Latest News & Updates data not found</h5>
              <div>
                <img
                  src="/images/blue horizontal.png"
                  alt="blue horizontal.png"
                  className="logo_dash img-fluid"
                />
              </div>
              <div className="mt-5">
                <h3 className="title_p opa">OneTN</h3>
              </div>
            </div>
          </>
        )}
      </div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"lg"}
        open={opens}
      >
        <DialogContent className="dialog-modal">
          <DialogContentText id="alert-dialog-description">
            <div>
              <span
                style={{ color: "#2260FF", cursor: "pointer" }}
                onClick={handleLatestnewsClose}
              >
                <ArrowBackIcon /> Back
              </span>
            </div>
            <div>
              <img
                src={
                  selectedLatestnews?.fileName
                    ? selectedLatestnews.fileName
                    : "/images/oneTn/white-image.png"
                }
                alt="green iguana"
                style={{ width: "100%" }}
              />
              <div className="crd-lists">
                <div className="card-event-det">
                  <h6>{selectedLatestnews?.title}</h6>
                  <p>
                    Source : <span>{selectedLatestnews?.source}</span>
                  </p>
                  <p>
                    Description : <span>{selectedLatestnews?.description}</span>
                  </p>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth={"sm"}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <center>
            <img src="/images/Banner/attention.gif" alt="" className="alert-image" />
          </center>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h4 className="text-center p-3">Are you sure ?</h4>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mb-2">
          <Button
            className="remove-api-modal-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button className="remove-api-modal-confirm" onClick={() => handleDeleteLatestnewsDetails()}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LatestNewsList;
