import React from 'react'
import Navbar from '../navbar-page/Navbar'
import { Container, Grid } from '@mui/material'
import Footer from '../footer-page/Footer'

const AboutPage = () => {
    return (
        <>
            <div className="back-color-about">
                <Navbar />
                <div className="mb-5">
                    <Container>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={12}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2} className="about-back-img">
                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx='abt-banner-title'>
                                        <h1 style={{ color: '#fff' }}>About <span className='one-place-2'>Us</span></h1>
                                        <p className='one-place-3'>
                                            OneTN is a centralized platform connecting startups with government startup schemes and support services in Tamil Nadu.
                                            Acting as a single access point, OneTN provides streamlined access to schemes, mentorship, funding, and ecosystem collaboration.
                                            It enhances operational efficiency, fosters stakeholder interaction, and empowers startups with tailored resources.
                                            By simplifying processes and supporting data-driven policymaking, OneTN aims to strengthen Tamil Nadu’s startup ecosystem and drive sustainable growth.
                                        </p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <div className="abt-banner-imag-div">
                                            <img src="/images/Aboutpage/map.png" alt="About-Map" className="abt-banner-imag" />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
            <div className="bg-animated">
                <Grid container justifyContent="center" alignItems="center" direction="row">
                    <Grid item sm={12} lg={3}>
                        <div className="anim-center-text mt-5">
                            <div>
                                <img src='/images/oneTn/anim-.png' alt="onetn" className="anim-cen-logo" />
                            </div>
                            <div className="anim-center-cont">
                                <h6 className="abt-emp-word">
                                    Empowering<br />
                                    Startup Journey Through
                                    <span style={{ fontWeight: '600' }}> Unified Go-TN Support</span>
                                </h6>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className='black-color-1'>
                <Grid container justifyContent="center" alignItems="center" direction="row">
                    <Grid item sm={12} md={6} lg={6}>
                        <img src="/images/Aboutpage/Imag1.png" alt="img_1" className='Abt-img_1' />
                    </Grid>
                    <Grid item sm={12} md={6} lg={6}>
                        <div>
                            <div className='border-color-abt'>
                                <p className='why-onetn'>Why OneTN?</p>
                                <h2 className='why-onetn-h2'>Access to All Schemes & Services <span style={{color:'gray'}}>in<span className='one-place'> One</span>Place</span></h2>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className='black-color-1'>
                <Grid container justifyContent="center" alignItems="center" direction="row">
                    <Grid item sm={12} md={6} lg={6}>
                        <div>
                            <div className='border-color-abt'>
                                <h6 className='border-color-abt-p'>The OneTN platform enables various government departments to list their startup-focused initiatives and support services. Startups can access these resources, tailored to their specific needs, all in one centralized platform.Access to the Ecosystem: OneTN maps the entire startup ecosystem, connecting startups with key stakeholders such as incubators, accelerators, investors, mentors, and government officials, fostering collaboration and growth. </h6>
                            </div>
                        </div>
                    </Grid>
                    <Grid item sm={12} md={6} lg={6}>
                        <img src="/images/Aboutpage/Imag2.png" alt="img_1" className='Abt-img_1' />
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    )
}

export default AboutPage;
