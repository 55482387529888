import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { IoIosList } from "react-icons/io";
import { FaRegCalendar } from "react-icons/fa6";
import DataContext from "../../../context/DataContext";
import { FormControl, InputBase, InputLabel, MenuItem, Select, ToggleButton, styled, Menu, Button, Typography, Autocomplete, TextField } from "@mui/material";
import CustomDateRangePicker from "../../common-pages/CustomDateRangePicker";
import { NavLink, useLocation } from "react-router-dom";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import SortIcon from '@mui/icons-material/Sort';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "24px",
  backgroundColor: "rgb(255 255 255)",
  border: "1px solid #00000017",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#d9d9d9",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "19ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));



const AdminEventFilter = ({
  setSearchInputValue,
  setSelectedPeriod, selectedPeriod,
  setSelectedType, selectedType,
  setSortBy, sortBy,
  setDepartmentId,
  setActiveView, activeView,
  name,
  handleVenueOpen,setPage
}) => {
  const { navigator, setStartDate, setEndDate, hasPrivilege } = useContext(DataContext);

  const handleEventAdd = () => {
    navigator("/admin/event/add");
  };
  const role = localStorage.getItem("role");
  useDidMountEffect(() => {    
    if (hasPrivilege('5') === true) {
      // departmentListApi()
    }
  }, [])
  const [departmentList, setDepartmentList] = useState([])
  const departmentListApi = () => {
    apiService(`department/list`, {}, 'post').then((result) => {
      if (result && result.data && result.data.department) {
        let data = []
        const val = {
          "id": 0,
          "name": 'All',
        }
        data.push(val)
        result.data.department.forEach(element => {
          data.push(element)
        });
        setDepartmentList(data)
      } else {
        let data = []
        const val = {
          "id": 0,
          "name": 'All',
        }
        data.push(val)
        setDepartmentList(data)
      }
    })
  }
  const [departmentName, setDepartmentName] = useState('All')
  const handleDepartment = (val) => {
    setDepartmentName(val)
    var depValue = departmentList.filter((list) => list.name === val)
    setDepartmentId(depValue[0].id)
  }


  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleTabClick = (newValue) => {
    setActiveView(newValue);
  };

  // const handleSearch = (e) => {
  //   setSearchInputValue(e.target.value);
  //   setPage(1)
  // };
  const handleSearch = (e) => {
    const timerId = setTimeout(() => {
      setSearchInputValue(e.target.value);
      setPage(1)
    }, 1500); 
    
    return () => clearTimeout(timerId);
  };


  const handleSelectPeriod = (value) => {
    setSelectedPeriod(value);
  };

  const handleSeletedType = (value) => {
    setSelectedType(value);
  };

  const handleOpenSortMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleSortBy = (value) => {
    setSortBy(value);
    handleCloseUserMenu();
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleVenueAdd = () => {
    navigator("/admin/event/add");
  };
  const location = useLocation();
  const shouldHideBackButton = ['/admin/event/venue'].includes(location.pathname);

  return (
    <section>
      {role === "CS" && (
        <div className="eventdepart-title mx-3">
          <h3 className="even-ead">Events</h3>
          <p>Explore Events conducted by various Departments</p>
        </div>
      )
      }
      <div className="double-clr">
        <div className="frt-gry">
          <div className="main-tab-adminpage">
            <NavLink className="startup-sup-btn" to={"/admin/event/list"}>
              Event Calendar
            </NavLink>
            <NavLink className={hasPrivilege('7') ? "startup-sup-btn-bor" : 'startup-sup-btn-bor1'} to={"/admin/event/history"}>
              {/* Events History */}
              Events Dashboard
            </NavLink>
            {/* {hasPrivilege('7') && (
              <NavLink className="startup-sup-btn1" to={"/admin/event/venue"}>
                Venue
              </NavLink>
            )} */}
          </div>
          <div>
            {(!shouldHideBackButton && hasPrivilege('2')) && (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{
                  textTransform: "none",
                  background: "#0061D4",
                  borderRadius: "10px",
                }}
                onClick={handleEventAdd}
              >
                Create Event
              </Button>
            )}
            {(shouldHideBackButton && hasPrivilege('8')) && (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{
                  textTransform: "none",
                  background: "#0061D4",
                  borderRadius: "10px",
                }}
                onClick={handleVenueOpen}
              >
                Create Venue
              </Button>
            )}
          </div>
        </div>
        <div className="sec-clrk">
          {/* {
            hasPrivilege('5') && (
              <div className="sec-btn-bg">
                <Autocomplete
                  size="small"
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  value={departmentName}
                  options={departmentList.map((val) => val.name)}
                  onChange={(e, selectedOptions) =>
                    handleDepartment(selectedOptions)
                  }
                  className="meetingListDropdown"
                  renderInput={(params) => <TextField {...params} label="Choose Department" />}
                />
              </div>
            )
          } */}
          {/* {
            (!shouldHideBackButton && hasPrivilege('6')) && (
              <div className="sec-btn-bg">
                <div className="all-btn-bg">
                  <button
                    className={
                      selectedType === "All" ? "secs-btn-1 active" : "secs-btn-1"
                    }
                    onClick={() => handleSeletedType("All")}
                  >
                    All events
                  </button>
                  <button
                    className={
                      selectedType === "myevent"
                        ? "secs-btn-1 active"
                        : "secs-btn-1"
                    }
                    onClick={() => handleSeletedType("myevent")}
                  >
                    My events
                  </button>
                </div>
              </div>
            )
          } */}
          <div className="sec-filtersk ml-auto">
            <div className="search-div">
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder={!shouldHideBackButton ? "Search event" : "Search venue"}
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleSearch}
                  type="search"
                />
              </Search>
            </div>
            {
              !shouldHideBackButton && (
                <>
                  <div>
                    <CustomDateRangePicker
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                  <div>
                    <FormControl variant="outlined" size="small" sx={{ width: 120 }}>
                      <InputLabel id="dropdown-label">Duration</InputLabel>
                      <Select
                        labelId="dropdown-label"
                        label="Select Option"
                        value={selectedPeriod}
                        variant="outlined"
                        style={{ borderRadius: 10 }}
                        onChange={(e) => handleSelectPeriod(e.target.value)}
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={"This Week"}>This Week</MenuItem>
                        <MenuItem value={"This Month"}>This Month</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {/* {
                    name === 'list' && (
                      <div className="frt-fil">
                        <div className="all-btn-bg">
                          <button
                            className={`secs-btn-1 ${activeView === 0 ? "active" : ""
                              }`}
                            onClick={() => handleTabClick(0)}
                          >
                            <span>
                              <IoIosList className="lists-icons" />
                            </span>
                            List
                          </button>
                          <button
                            className={`secs-btn-2 ${activeView === 1 ? "active" : ""
                              }`}
                            onClick={() => handleTabClick(1)}
                          >
                            <span>
                              <FaRegCalendar className="calander-icons" />
                            </span>
                            Calendar View
                          </button>
                        </div>
                      </div>
                    )
                  } */}
                  <div>
                    <ToggleButton className="toggle-fil" onClick={handleOpenSortMenu}>
                      {/* <img src="/images/filter icon.svg" alt="Filter" /> */}
                      <SortIcon sx={{color: '#000'}}/>
                    </ToggleButton>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem onClick={() => handleSortBy(true)}>
                        <Typography
                          textAlign="center"
                          sx={{ color: sortBy === true ? "#0061D4" : "" }}
                        >
                          Start Date Ascending
                        </Typography>
                      </MenuItem>
                      <MenuItem onClick={() => handleSortBy(false)}>
                        <Typography
                          textAlign="center"
                          sx={{ color: sortBy === false ? "#0061D4" : "" }}
                        >
                          Start Date Descending
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>

    </section>
  );
};

export default AdminEventFilter;
