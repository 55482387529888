import React, { useState, useContext } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {
  Dialog,
  Paper,
  DialogContent,
  DialogContentText,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import AdminBlogFilter from "./AdminBlogFilter";
import BlogDetails from "../../Media-module/Media-layout-page/Blog-Page/BlogDetails";
import cryptodecryptJS from "../../../hooks/cryptodecryptJS";

const AdminBlogList = () => {
  const {
    navigator,
    fullScreen,
    setPageLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    hasPrivilege,
  } = useContext(DataContext);
  useDidMountEffect(() => {
    setStartDate("");
    setEndDate("");
  }, []);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedBlog, setSelectedBlog] = useState(null);

  const [selectedBlogIndex, setSelectedBlogIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [blogData, setBlogData] = useState([]);
  let departmentId = localStorage.getItem("departmentId");
  useDidMountEffect(() => {
    listApi();
  }, [searchInputValue, page, startDate, endDate]);

  const listApi = () => {
    setPageLoading(true);
    setBlogData([]);
    var data = {
      listSize: 5,
      pageNumber: page,
      departmentId: departmentId,
      searchString: searchInputValue,
      fromDate: startDate ? startDate : "",
      toDate: endDate ? endDate : "",
    };
    apiService("blog/list", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result?.data) {
          setTotalPage(result.data.totalPages);
          if (result?.data?.blog) {
            setBlogData(result.data.blog);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching Blogs:", err);
      });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  useDidMountEffect(() => {
    if (blogData.length > 0) {
      setSelectedBlog(blogData[selectedBlogIndex]);
    } else {
      setSelectedBlog(null);
      setSelectedBlogIndex(0);
    }
  }, [blogData]);
  const handleBlogClick = (event, index) => {
    setSelectedBlog(event);
    setSelectedBlogIndex(index);
  };

  const [opens, setOpens] = useState(false);
  const [BlogId, setBlogId] = useState(0);
  const handleViewBlogDetails = (val) => {
    setBlogId(val);
    setOpens(true);
  };
  const handleBlogClose = () => {
    setOpens(false);
  };
  const handleEditBlogDetails = (id) => {
    navigator(`/admin/blog/${btoa(id)}`);
  };
  const [openModal, setOpenModal] = useState(false);
  const [blogdelId, setBlogdelId] = useState([]);
  const handleOpenModal = (id) => {
    setOpenModal(true);
    setBlogdelId(id);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleDeleteBlogDetails = () => {
    apiService(`blog/remove?id=${blogdelId}`, "", "delete")
      .then((result) => {
        if (result?.data?.responseStatus === "Success") {
          handleCloseModal()
          listApi();
        }
      })
      .catch((err) => {
        console.log(err);

      });
  };
  return (
    <div className="admin-event-page">
      <AdminBlogFilter setSearchInputValue={setSearchInputValue} setPage={setPage}/>
      <div className="event-datas-list py-5">
        {blogData.length > 0 ? (
          <>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={5}
            >
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                {blogData.length > 0 && (
                  <div className="table_main_div">
                    <TableContainer component={Paper}>
                      <Table
                        sx={{
                          borderCollapse: "separate",
                          borderSpacing: "0 10px",
                        }}
                      >
                        <TableBody sx={{ position: "relative" }}>
                          {blogData.map((list, index) => (
                            <>
                              <TableRow
                                sx={{
                                  position: "relative",
                                  "&:after": {
                                    content: '""',
                                    position: "absolute",
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    borderRadius: "20px",
                                    pointerEvents: "none",
                                    marginBottom: "-1px",
                                  },
                                  "& > *": {
                                    padding: "10px",
                                    backgroundColor: "#fff",
                                    "&:last-of-type": {
                                      borderTopRightRadius: "20px",
                                      borderBottomRightRadius: "20px",
                                    },
                                  },
                                  marginBottom: "10px",
                                }}
                                key={index}
                                className={
                                  selectedBlogIndex === index
                                    ? "Sup-list selected"
                                    : "Sup-list"
                                }
                                onClick={() => handleBlogClick(list, index)}
                              >
                                <TableCell>
                                  <div className="px-3">
                                    <Typography
                                      variant="h6"
                                      className="list-2-clr"
                                    >
                                      {list.title}
                                    </Typography>
                                    <div className="list-2-dflex">
                                      <span className="list-2-grey">
                                        Author Name :
                                      </span>
                                      <span className="list-2-black">
                                        {list.author ? list.author : "-"}
                                      </span>
                                    </div>
                                    <div className="list-2-dflex">
                                      <span className="list-2-grey">
                                        Author Email :
                                      </span>
                                      <span className="list-2-black">
                                        {list.authorEmail &&
                                          cryptodecryptJS(list.authorEmail)}
                                      </span>
                                    </div>
                                    <div className="list-2-dflex">
                                      <span className="list-2-grey">
                                        View Count :{" "}
                                      </span>
                                      <span className="list-2-black">
                                        {list.viewedCount}
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="events-date-box">
                                    {list.createdTime && (
                                      <div className="date-blue-box mr-2">
                                        <p>{list.createdTime}</p>
                                      </div>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div>
                                  <span className="list-2-black" style={{color:list.publish === true ? '#00AA00' : "orangered",fontWeight:'500'}}>{list.publish === true ? "Published":"Not Published"}</span>
                                  </div>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                      {totalPage > 1 && (
                        <div className="event-pagination">
                          <Stack spacing={2}>
                            <Pagination
                              count={totalPage}
                              page={page}
                              onChange={handleChange}
                            />
                          </Stack>
                        </div>
                      )}
                    </TableContainer>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                {selectedBlog && (
                  <Card sx={{ maxWidth: 385 }} className="card-event-list">
                    <img
                      src={
                        selectedBlog.imageUrl
                          ? selectedBlog.imageUrl
                          : "/images/oneTn/white-image.png"
                      }
                      alt="Event_Banner"
                      style={{ width: "100%" }}
                    />
                    <div className="crd-lists">
                      <div className="card-event-det">
                        <h6>{selectedBlog.title}</h6>
                        <p>
                          Author Email :{" "}
                          <span>
                            {selectedBlog.authorEmail &&
                              cryptodecryptJS(selectedBlog.authorEmail)}
                          </span>
                        </p>
                        <p>
                          View Count : <span>{selectedBlog.viewedCount}</span>
                        </p>
                        <p>
                          Created Date : <span>{selectedBlog.createdTime}</span>
                        </p>
                      </div>
                    </div>
                    <div className="admin-crd-actions">
                      <button
                        className="admin-viewbtn"
                        onClick={() => handleViewBlogDetails(selectedBlog.id)}
                      >
                        View
                      </button>
                      {hasPrivilege("58") && (
                        <button
                          className="ml-3 admin-editbtn "
                          onClick={() => handleEditBlogDetails(selectedBlog.id)}
                        >
                          Edit
                        </button>
                      )}
                      {hasPrivilege("59") && (
                        <button
                          className="ml-3 admin-deletebtn"
                          onClick={() =>
                            handleOpenModal(selectedBlog.id)
                          }
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </Card>
                )}
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <div className="text-center nodatacss_admin">
              <h5 className="mb-4">Blog data not found</h5>
              <div>
                <img
                  src="/images/blue horizontal.png"
                  alt="blue horizontal.png"
                  className="logo_dash img-fluid"
                />
              </div>
              <div className="mt-5">
                <h3 className="title_p opa">OneTN</h3>
              </div>
            </div>
          </>
        )}
      </div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"lg"}
        open={opens}
      >
        <DialogContent className="dialog-modal">
          <DialogContentText id="alert-dialog-description">
            <div>
              <span
                style={{ color: "#2260FF", cursor: "pointer" }}
                onClick={handleBlogClose}
              >
                <ArrowBackIcon /> Back
              </span>
            </div>
          </DialogContentText>
          <BlogDetails blogId={BlogId} via={"admin"} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth={"sm"}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <center>
            <img src="/images/Banner/attention.gif" alt="" className="alert-image" />
          </center>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h4 className="text-center p-3">Are you sure ?</h4>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mb-2">
          <Button
            className="remove-api-modal-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button className="remove-api-modal-confirm" onClick={() => handleDeleteBlogDetails()}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AdminBlogList;
