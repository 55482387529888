import React, { useContext, useState } from "react";
import AdminLayoutsidebar from "./AdminLayoutsidebar";
import { Box, Dialog, DialogContent, Drawer, Menu, MenuItem, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DataContext from "../../context/DataContext";
import Changepassword from "../common-pages/Changepassword";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import NodalProfileupdate from "../home-module/home-page/NodalProfileupdate";
import { FaArrowLeft } from "react-icons/fa";

const AdminLayoutNavbar = () => {
  const { navigator, checkSessionTimout, updateopen, handleModalClosed, setUpdateopen } = useContext(DataContext);
  useDidMountEffect(() => {
    checkSessionTimout();
  }, []);
  const [open, setOpen] = useState(false);
  let continueWith = localStorage.getItem("continueWith");
  let PersonaType = localStorage.getItem("role");
  let logo = localStorage.getItem('logo')
  let name = "Admin";
  name = localStorage.getItem("name");
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  //   const handleDashboardPage = () => {
  //     navigator("/admin");
  //   };
  let lang = localStorage.getItem("lang");
  const logoutBtn = () => {
    handleCloseUserMenu();
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("lang", lang);
    navigator("/");
  };
  const [openPassword, setOpenPassword] = useState(false);
  const handlePasswordOpen = () => {
    setOpenPassword(true);
  };
  const handlePasswordClose = () => {
    setOpenPassword(false);
  };
  //   const handleNavigate = ()=>{
  //     navigator('/')
  // }
  return (
    <>
      <nav className="admin-navbar">
        <div className="set-flex">
          <Box
            sx={{
              display: { md: "none", xs: "block" },
              color: "white",
              alignItems: "center",
            }}
          >
            <MenuIcon onClick={toggleDrawer(true)} className="mr-3" />
            {/* <img 
                        src="/images/menu_icon.png"
                        alt=""
                    /> */}
          </Box>
          {/* <Box sx={{ marginRight: '15px', cursor: 'pointer' }} onClick={() => handleNavigate()}>
            <span style={{ color: '#fff' }}><FaArrowLeft style={{ marginRight: '5px' }} />Back</span>
          </Box> */}
          {/* <Box
            className="search_div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <img
              src="/images/admin/search-white.png"
              alt=""
              className="navsearch_icon"
            />
            <input placeholder="Search" className="navbar-search" />
          </Box> */}
          <div className="ml-auto nav_menu_div">
            <ul>
              {/* <li>
                <img
                  src="/images/admin/setting.png"
                  alt=""
                  className="nav_menu_icon"
                />
              </li> */}
              {/* <li>
                <img
                  src="/images/admin/bell.png"
                  alt=""
                  className="nav_menu_icon"
                />
              </li> */}
              <li>
                <span className="username_span">{name}</span>

                {(logo !== "undefined" && logo !== null) ? (<img
                  className="user-brandnewlogo c-pointer"
                  src={logo}
                  alt={'Profile_pic'}
                  onClick={handleOpenUserMenu}
                />) :

                  (<img
                    src="/images/admin/profile.png"
                    alt=""
                    className="nav_menu_icon c-pointer"
                    onClick={handleOpenUserMenu}
                  />)}
              </li>
            </ul>
          </div>
        </div>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {/* {PersonaType !== "SUPER-ADMIN" && (<MenuItem onClick={handleDashboardPage}>
                        <Typography textAlign="center">Dashboard</Typography>
                    </MenuItem>)} */}
          {PersonaType === "NODAL" && (<MenuItem onClick={() => setUpdateopen(true)}>
            <Typography textAlign="center">Update Profile</Typography>
          </MenuItem>)}
          {continueWith === "false" && (
            <MenuItem onClick={handlePasswordOpen}>
              <Typography textAlign="center">Change Password</Typography>
            </MenuItem>
          )}
          <MenuItem onClick={logoutBtn}>
            <Typography textAlign="center">Logout</Typography>
          </MenuItem>
        </Menu>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          <AdminLayoutsidebar onClose={toggleDrawer(false)} />
        </Drawer>
      </nav>
      <Dialog open={openPassword} maxWidth="md">
        <Changepassword handlePasswordClose={handlePasswordClose} />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={updateopen}
        onClose={handleModalClosed}
      >

        <DialogContent>
          <NodalProfileupdate />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdminLayoutNavbar;
