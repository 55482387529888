import React, { useContext, useState } from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import notifyService from "../../../api/notifySerivce";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import cryptoJS from "../../../hooks/cryptoJS";
const Footer = () => {
  const {
    validateNumberonly,
    VisuallyHiddenInput,
    validatePhoneNumber,
    setPageLoading,
    isWhitespace,
    validateEmail,
    removeItem,
    t
  } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const [depId, setDepId] = useState("");
  const lang = localStorage.getItem("lang");
  console.log(lang);
  const [departId, setDepartId] = useState(0)
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    reset,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    values: {
      attachments: [
        {
          id: 0,
          file: "",
          fileName: "",
          imageURL: "",
        },
      ],
    },
  });
  $(document).ready(function () {
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 100) {
        $("#backtotop").fadeIn();
      } else {
        $("#backtotop").fadeOut();
      }
    });
    $("#backtotop").click(function () {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        600
      );
      return false;
    });
  });

  const scrollToTop = () => {
    $("html, body").animate(
      {
        scrollTop: 0,
      },
      600
    );
  };
  const [chatopen, setChatOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const handleClickOpen = () => {
    setChatOpen(true);
    setIsDepartmentSelected(false);
    setIsProjectSelected(false);
  };

  const handleModalClose = () => {
    reset()
    setChatOpen(false);
  };
  useDidMountEffect(() => {
    departmentdropdownList();
    projectDropdownlist()
  }, [departId]);
  const [departmentList, setDepartmentList] = useState([]);
  const departmentdropdownList = () => {
    apiService(`department/name/list`, {}, "unauthpost")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data) {
              var values = result.data;
              setDepartmentList(values.department);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [projectlist, setProjectList] = useState([])
  const projectDropdownlist = () => {
    var req = {
      departmentId: departId
    }
    apiService(`project/name/list`, req, "unauthpost")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.projects) {
              var dropDown = result.data.projects;
              setProjectList(dropDown);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [smartOpen, setSmartOpen] = useState(false)
  const onSubmit = (data) => {
    setPageLoading(true);
    if (data.email) {
      data.email = cryptoJS(data.email)
    }
    if (data.phone) {
      data.phone = cryptoJS(data.phone)
    }
    if (data.project) {
      var projId = projectlist.filter((list) => list.name === data.project)
      data.projectId = projId[0].id
    }
    if (data.department) {
      var depid = departmentList.filter((list) => list.name === data.department)
      data.departmentId = depid[0].id
    }
    apiService("support/raiseticket", data, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data && result.data.responseStatus === "Success") {
          setSmartOpen(true)
          setChatOpen(false);
          reset();
        }
      })
      .catch((err) => { });
  };
  const handleFeeMode = (value) => {
    setValue("category", value);
    clearErrors("category");
  };
  const {
    fields: photosFields,
    append: photosAppend,
    remove: photosRemove,
  } = useFieldArray({
    control,
    name: "attachments",
  });
  const addPhotoDetails = () => {
    photosAppend({
      id: 0,
      file: "",
      fileName: "",
      imageURL: "",
    });
  };

  let logoselecetdFile = "";
  const handleFileUpload = (
    event,
    filekey,
    fileName,
    fileURL,
    arrayName,
    index
  ) => {
    //
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      if (logoselecetdFile) {
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if (
          img_crt_type === "jpeg" ||
          img_crt_type === "jpg" ||
          img_crt_type === "png"
        ) {
          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            // setValue(filekey, ImageValue)
            // setValue(fileName, img_name)
            // setValue(fileURL, logourl1)
            if (arrayName !== "") {
              setValue(`${arrayName}.${index}.${filekey}`, ImageValue);
              setValue(`${arrayName}.${index}.${fileName}`, img_name);
              setValue(`${arrayName}.${index}.${fileURL}`, logourl1);
            } else {
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              setValue(fileURL, logourl1);
            }
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            addPhotoDetails();
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalid",
            "Please check your file format"
          );
        }
      }
    }
  };

  const deletePhotos = (index) => {
    photosRemove(index);
  };
  // const handleDepartment = (val) => {
  //   setValue("department", val);
  //   clearErrors("department");
  // }
  // const handleProjects = (val) => {
  //   setValue("project", val);
  //   clearErrors("project");
  // }
  const [isProjectSelected, setIsProjectSelected] = useState(false);
  const [isDepartmentSelected, setIsDepartmentSelected] = useState(false);
  // const handleDepartment = (val) => {
  //   setIsDepartmentSelected(!!val); 
  //   setValue("department", val);
  //   clearErrors("department");
  // };

  // const handleProjects = (val) => {
  //   setIsProjectSelected(!!val); 
  //   setValue("project", val);
  //   clearErrors("project");
  // };
  const handleDepartment = (val) => {
    var depid = departmentList.filter((list) => list.name === val)
    setDepartId(depid[0].id)
    debugger

    setIsDepartmentSelected(true);
    setValue("department", val);
    setValue("project", "");
    clearErrors("department");
    clearErrors("project");

  };

  const handleProjects = (val) => {
    setIsProjectSelected(true);
    setValue("project", val);
    clearErrors("project");
    clearErrors("department");
  };
  const availsmartClose = () => {
    setSmartOpen(false)
}
  return (
    <>
      <footer className="footer">
        <div className="footer_details ">
          <div className="container">
            <section className="">
              <div className="row">
                <div className="col-lg-6 col-md-6 ">
                  <div className="d-flex align-items-start mt-2">
                    {/* <div className='mr-2'>
                      <img src={'/images/oneTn/933px-TamilNadu_Logo 1.png'} alt="#" />
                    </div> */}
                    {/* <div><p>Micro, Small and Medium <br />Enterprises Department</p></div> */}
                  </div>
                  {/* <Link >
                    <img src="/images/oneTn/new_logo.png" className="p-0 footer-img" alt="" />
                  </Link> */}
                  <Link to="/" onClick={removeItem}>
                    <img
                      className="img-fluid nav-logo-profile"
                      src="/images/oneTn/new_logo.png"
                      alt=""
                    />
                  </Link>
                  <div className='mt-2'>
                  {lang === "en" ? (
                    <p style={{color:'#fff',opacity: 0.75}}>For queries or further assistance, <br/>contact us at "support@onetn.in"</p>
                  ):(
                    <p style={{color:'#fff',opacity: 0.75}}>For queries or further assistance, <br/>contact us at "support@onetn.in"</p>
                )
                }
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 footer-text-gray"></div>
                {/*  <h3 className="my-3 font-weight-bolder c-gray">Quick Links</h3>
                  <ul className="list-unstyled mb-0">
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='' onClick={scrollToTop} >
                        For Startups
                      </Link>
                    </li>
                    {/* <li className='pb-3'>
                      <Link className="footer-text-gray" to='' onClick={scrollToTop} >
                        For Enablers
                      </Link>
                    </li> */}
                {/*}
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='/events/list' onClick={scrollToTop} >
                        Events
                      </Link>
                    </li>
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='/ecosystem-info' onClick={scrollToTop} >
                        Ecosystem
                      </Link>
                    </li>
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='' onClick={scrollToTop} >
                        Entities
                      </Link>
                    </li>
                    {/* <li className='pb-3'>
                      <Link className="footer-text-gray" to='/resource-page' onClick={scrollToTop} >
                        Entities
                      </Link>
                    </li> */}
                {/* <li className='pb-3'>
                      <Link className="footer-text-gray" to='/tender' onClick={scrollToTop} >
                        Tenders
                      </Link>
                    </li>
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='/careers' onClick={scrollToTop} >
                        Careers
                      </Link>
                    </li> */}
                {/*}  </ul>

                </div> */}
                <div className="col-lg-3 col-md-3 footer-text-gray">
                  <h3 className="my-3 font-weight-bolder c-gray">
                    {t("footer.link")}
                  </h3>
                  <ul className="list-unstyled mb-0">
                    <li className="pb-3">
                      <Link
                        className="footer-text-gray"
                        to="/privacy-policy"
                        onClick={scrollToTop}
                      >
                        {t("footer.Privacy")}
                      </Link>
                    </li>
                    <li className="pb-3">
                      <Link
                        className="footer-text-gray"
                        to="/terms-condition"
                        onClick={scrollToTop}
                      >
                        {t("footer.Terms")}
                      </Link>
                    </li>
                    {/* <li className="pb-3">
                      <Link
                        className="footer-text-gray"
                      to='/contactus'
                      onClick={scrollToTop}
                      >
                        Contact & Support
                      </Link>
                    </li> */}
                    {/*
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='' onClick={scrollToTop} >
                        FAQ's Page
                      </Link>
                    </li> */}
                  </ul>
                </div>
                {/* <div className="col-lg-6 col-md-6">
                </div> */}
                {/* <div className="col-lg-6 col-md-6">
                  <div className='my-3'>
                    <div className="social_icon">
                      <Link to={'https://www.facebook.com/TheStartupTN/'} target='_blank'>
                        <img src='/images/facebook.svg' className='img-fluid' alt="" />
                      </Link>
                      <Link to={'https://x.com/TheStartupTN'} target='_blank'>
                        <img src='/images/twitter.svg' className='img-fluid' alt="" />
                      </Link>
                      <Link to={'https://www.linkedin.com/company/thestartuptn/?originalSubdomain=in'} target='_blank'>
                        <img src='/images/linkedin.svg' className='img-fluid' alt="" />
                      </Link>
                      <Link to={'https://www.instagram.com/thestartuptn/'} target='_blank'>
                        <img src='/images/instagram.svg' className='img-fluid' alt="" />
                      </Link>
                      <Link to={'https://www.youtube.com/channel/UCr0du18taGeXH35dZZD4RnQ'} target='_blank'>
                        <img src='/images/youtube.svg' className='img-fluid' alt="" />
                      </Link>
                    </div>
                  </div>
                </div> */}
              </div>
            </section>
            {/* <p className='hr' /> */}
            {/* <div className="text-center">
            

          </div> */}
          </div>
        </div>
        {/* <div className="comment">
          <img
            src="/images/comment.png"
            alt=""
            class="img-fluid"
            onClick={handleClickOpen}
          />
        </div> */}
        <span id="backtotop" title="Back to top" style={{ display: "none" }}>
          &uarr;
        </span>
      </footer>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={chatopen}
        onClose={handleModalClose}
      >
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="support-msg mb-3">
              <div>
                <h3>How Can We Help?</h3>
                {/* <p>Choose the related issue</p> */}
              </div>
              <div className="support-msg-box">
                <p class="text-danger m-0">
                  For other issues, please mail us at tech@startuptn.in
                </p>
              </div>
            </div>
            <Grid container spacing={1}>
              <Grid item lg={4} md={12} sm={12} xs={12} className="mb-3">
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Full Name is required",
                    validate: {
                      noWhitespace: (value) =>
                        !isWhitespace(value) || "Whitespace not allowed",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Full Name *"
                      placeholder="Enter Full Name"
                      fullWidth
                      type="text"
                      {...field}
                      error={Boolean(errors.name && errors.name)}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.name && errors.name.message}
                </FormHelperText>
              </Grid>
              <Grid item lg={4} md={12} sm={12} xs={12} className="mb-3">
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "E-Mail is required",
                    validate: validateEmail,
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="E-Mail *"
                      placeholder="Enter your E-Mail"
                      fullWidth
                      type="text"
                      {...field}
                      error={Boolean(errors.email && errors.email)}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.email && errors.email.message}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} lg={4} md={12} sm={12} className="mb-3">
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Phone Number is required",
                    validate: validatePhoneNumber,
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Phone Number *"
                      placeholder="Enter Phone Number"
                      fullWidth
                      type="text"
                      {...field}
                      onKeyDown={(e) => validateNumberonly(e)}
                      inputProps={{ maxLength: 10 }}
                      error={Boolean(errors.phone && errors.phone)}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.phone && errors.phone.message}
                </FormHelperText>
              </Grid>
              <Grid item lg={4} md={12} sm={12} xs={12} className="mb-3">
                <Controller
                  name="title"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Title is required",
                    validate: {
                      noWhitespace: (value) =>
                        !isWhitespace(value) || "Whitespace not allowed",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Title *"
                      placeholder="Enter Title"
                      fullWidth
                      type="text"
                      {...field}
                      error={Boolean(errors.title && errors.title)}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.title && errors.title.message}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} lg={4} md={12} sm={12} className="mb-3">
                <Controller
                  name="department"
                  control={control}
                  defaultValue=""
                  rules={{ required: !isProjectSelected ? "Department is required" : false }}
                  render={({ field }) => (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      {...field}
                      options={departmentList.map((val) => val.name)}
                      onChange={(e, selectedOptions) =>
                        handleDepartment(selectedOptions)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label={!isProjectSelected ? "Department *" : "Department"} />
                      )}
                    // disabled={readyOnlyValue}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.department && errors.department.message}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} lg={4} md={12} sm={12} className="mb-3">
                <Controller
                  name="project"
                  control={control}
                  defaultValue=""
                  rules={{ required: !isDepartmentSelected ? "Project is required" : false }}
                  render={({ field }) => (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      {...field}
                      options={projectlist.map((val) => val.name)}
                      onChange={(e, selectedOptions) =>
                        handleProjects(selectedOptions)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label={!isDepartmentSelected ? "Project *" : "Project"} />
                      )}
                    // disabled={readyOnlyValue}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.project && errors.project.message}
                </FormHelperText>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                <Controller
                  name="query"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Query is required",
                    validate: {
                      noWhitespace: (value) =>
                        !isWhitespace(value) || "Whitespace not allowed",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-multiline-static"
                      label="Enter your Query *"
                      fullWidth
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 500 }}
                      defaultValue=""
                      error={Boolean(errors.query && errors.query)}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.query && errors.query.message}
                </FormHelperText>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className=" mb-3">
                <div className="form-group m-0 ">
                  <div className="d-flex align-items-center mb-3">
                    <FormLabel shrink="true" className="input_Lable">
                      <span className="thick-blue">
                        {" "}
                        Attachments (Optional){" "}
                      </span>
                    </FormLabel>
                  </div>

                  <div className="d-flex">
                    {photosFields.map((field, index) => (
                      <div>
                        <Controller
                          name={`attachments.${index}.imageURL`}
                          control={control}
                          defaultValue=""
                          rules={{
                            required: false,
                          }}
                          render={({ field }) => (
                            <>
                              <Button
                                {...field}
                                fullWidth
                                className="imgupload_div"
                                component="label"
                                size="small"
                                onChange={(e) =>
                                  handleFileUpload(
                                    e,
                                    "file",
                                    "fileName",
                                    "imageURL",
                                    "attachments",
                                    index
                                  )
                                }
                              >
                                {getValues(`attachments.${index}.imageURL`) ? (
                                  <>
                                    <div className="">
                                      <img
                                        src={getValues(
                                          `attachments.${index}.imageURL`
                                        )}
                                        className="vehicle_logo"
                                        alt={`attachments.${index}.fileName`}
                                        style={{
                                          width: "67px",
                                          height: "67px",
                                          objectFit: "contain",
                                          border: "1px solid gainsboro",
                                          borderRadius: " 5px",
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="disPhotos">
                                    <VisuallyHiddenInput type="file" />
                                    <img
                                      src="/images/Attachment.png"
                                      className="no_logo"
                                      alt={`attachments.${index}.fileName`}
                                      style={{
                                        width: "67px",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                )}
                                <div></div>
                              </Button>
                              {getValues(`attachments.${index}.imageURL`) && (
                                <div className="text-center">
                                  <DeleteForeverIcon
                                    color="error"
                                    onClick={() => deletePhotos(index)}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="text-center">
              <button className="next-btn1" onClick={() => handleModalClose()}>
                Close
              </button>
              <button className="next-btn" type="submit">
                Submit
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <div>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={smartOpen}
          onClose={availsmartClose}
        >
          <DialogContent>
            <div className="text-center">
              <img src={'/images/Banner/gif_.gif'} alt="" className="sucess-image" />
              <h4>Thank you for submitting your request.</h4>
              <p>Your query has been received and is under review. Our support team will respond to you shortly. We appreciate your trust in us.</p>
            </div>
            <div className='text-center'>
              <Button className='clear-btn' onClick={() => availsmartClose()}>
                Close
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default Footer;
