import React, { useContext, useEffect } from "react";
import DataContext from "../../../context/DataContext";


const Language = () => {
  const { changeLanguage, useLang } = useContext(DataContext);
  let lang = localStorage.getItem("lang");
  useEffect(() => {

    // if (lang === null) {
    //   setValue("lange", "en");
    // } else {
    //   setValue("lange", lang);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLang]);
  return (
    <form className="d-flex align-items-center">
      <img src="/images/lang.png" class="lang-i" alt="lang-icon" />
      <label class="switch lag_select c-pointer">
        <input
          type="checkbox"
          onInput={(e) => changeLanguage(useLang)}
          id="check"
          value={useLang}
          style={{ display: 'none' }}
        />
        {lang === "en" ?"தமிழ்" :  "English" }
      </label>
    </form>
  );
};

export default Language;
