import { Autocomplete, Button, FormHelperText, Grid, TextField } from "@mui/material";
import Chip from "@mui/material/Chip";
import { FaArrowLeft } from "react-icons/fa6";
import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DataContext from "../../../context/DataContext";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import notifyService from "../../../api/notifySerivce";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SearchableDropdown from "../event-details/SearchableDropdown";
import { RiCloseCircleFill } from "react-icons/ri";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { useParams } from "react-router-dom";
import cryptodecryptJS from "../../../hooks/cryptodecryptJS";
import cryptoJS from "../../../hooks/cryptoJS";
const DepartmentAdd = () => {
  const { id } = useParams()
  let departmentId
  if (id != null) {
    departmentId = atob(id)
  }
  const { setPageLoading, navigator, VisuallyHiddenInput, validateEmail, validateNumberonly, Accordion, AccordionSummary, AccordionDetails } = useContext(DataContext);
  const [formData, setFormData] = useState({})
  const { handleSubmit, control, setValue, reset, setError, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });
  const {
    fields: serviceFields,
    append: serviceAppend,
    remove: serviceRemove,
  } = useFieldArray({
    control,
    name: "projects",
  });
  const {
    fields: brancheFields,
    append: brancheAppend,
    remove: brancheRemove,
  } = useFieldArray({
    control,
    name: "branches",
  });
  const {
    fields: teamFields,
    append: teamAppend,
    remove: teamRemove,

  } = useFieldArray({
    control,
    name: "teams",
  });
  if (brancheFields.length === 0) {
    brancheAppend({
      id: 0,
      street: "",
      district: "",
      state: "",
      pincode: "",
    });
  }
  if (serviceFields.length === 0) {
    serviceAppend({
      id: 0,
      name: "",
      description: "",
      categoryId: "",
      beneficiaries: [],
      impact: "",
      milestone: "",
      initiatives: ""
    });
  }
  useDidMountEffect(() => {
    statedropdownApi();
    sectorsdropdownApi()
    stagesdropdownApi()
    dropdownApi();
    categoryApi()
    beneficiariesApi()
    if (departmentId != null) {
      departmentGetApi(departmentId)
    }
  }, []);

  // stage dropdown
  const [stages, setStages] = useState([]);
  const stagesdropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"stages"}`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setStages(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  // sector Dropdown
  const [sectors, setSectors] = useState([]);
  const sectorsdropdownApi = () => {
    apiService(`home/sector/list`, "", "get")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data) {
              var values = result.data.sectors;
              setSectors(values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  // state api
  const [states, setStates] = useState([]);
  const statedropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"states"}`, "", "unauthget")
      .then((result) => {

        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropdown = result.data.dropDownValues;
              setStates(dropdown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  // district api
  const [district, setDistrict] = useState([]);
  const dropdownApi = () => {
    const req = {
      lang:"ENG"
    }
    apiService(`home/district/list1`,req, "post")
      .then((result) => {
        if (result && result.data && result.data.districts) {
          var values = result.data.districts;
          setDistrict(values);
        }
      })
      .catch((err) => { });
  }
  const [beneficiariesList, setBeneficiariesList] = useState([]);
  const beneficiariesApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"beneficiaries"}`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setBeneficiariesList(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [categoryList, setCategoryList] = useState([])
  const categoryApi = () => {
    apiService('home/category/list?type=project', "", "get").then((result) => {
      if (result && result.data && result.data.categories) {
        setCategoryList(result.data.categories)
      }
    }).catch((err) => {

    });
  }
  const departmentGetApi = (id) => {
    setFormData({})
    setPageLoading(true)
    apiService(`department/get?id=${id}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data
          // if (data.state === "Tamil Nadu") {
          //   
          //   var districtId = district.filter((list) => list.id === data.districtId)
          //   
          //   data.districtId = data.district
          // }
          if (data.logoName) {
            data.logoUrl = data.logoName
          }
          if (data.branches) {
            data.branches.forEach(element => {
              // if (element.state === "Tamil Nadu") {
              //   var districtId = district.filter((list) => list.id === element.districtId)
              //   element.districtId = districtId[0].name
              // }
              addBrancheAddressDetails()
            });
          }
          if (data.projects) {
            // data.projects.forEach(element => {
            //   let catePush = []
            //   element.sector.filter((category) => {
            //     var categoryId = sectors.filter((list) => list.name === category)
            //     return catePush.push(categoryId[0].id)
            //   })
            //   element.sector = catePush
             
            // });
            addServicesBtn()
          }
          if (data.authorityEmail) {
            data.authorityEmail = cryptodecryptJS(data.authorityEmail)
          }

          setFormData(data)

        }
      })
      .catch((err) => { });
  }
  // user search api
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const handleSearchChange = (event) => {
    setFilteredOptions([])
    setValue("authorityUserId", '');
    setauthorityUserId('');
    const value = event.target.value;
    setIsOpen(false);
    if (value !== '') {
      var req = {
        searchString: value,
      };
      apiService("user/search", req, "post")
        .then((result) => {
          if (result && result.data && result.data.responsemodelList) {
            setFilteredOptions(result.data.responsemodelList);
            if (value !== "") {
              setIsOpen(true);
            }
          }
        })
        .catch((err) => { });
    } else {
      setFilteredOptions([])
      setValue("authorityUserId", '');
      setauthorityUserId('');
    }
  };
  const [authorityUserId, setauthorityUserId] = useState();
  const handleOptionClick = (option, name) => {
    setValue("authorityUserId", name);
    clearErrors("authorityUserId");
    setauthorityUserId(option.id);
    setIsOpen(false);
  };

  const handleSelectState = (value) => {
    setValue("state", value);
    clearErrors("state");
    setValue("district", "");
    clearErrors("district");
  }
  const handleSelectDistrict = (value) => {
    setValue("district", value);
    clearErrors("district");
  };

  const addBrancheAddressDetails = () => {
    brancheAppend({
      id: 0,
      street: "",
      district: "",
      state: "",
      pincode: "",
    });
  };
  const handleaddSelectState = (value, index) => {
    setValue(`branches.${index}.state`, value);
    clearErrors(`branches.${index}.state`);
    setValue(`branches.${index}.district`, "");
    clearErrors(`branches.${index}.district`);
  };
  const handleaddSelectDistrict = (value, index) => {
    setValue(`branches.${index}.district`, value);
    clearErrors(`branches.${index}.district`);
  };
  const brancheAddressRemove = (index) => {
    var id = getValues(`branches.${index}.id`);
    if (id !== 0) {
      apiService(`department/branch/remove?id=${id}`, '', 'delete').then((result) => {
        if (result && result.data) {
          brancheRemove(index);
        }
      }).catch((err) => {
      });
    } else {
      brancheRemove(index);
    }
  };


  const [expanded, setExpanded] = useState('0');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const addServicesBtn = () => {
    serviceAppend({
      id: 0,
      name: "",
      description: "",
      categoryId: "",
      beneficiaries: [],
      sector: [],
      stage: [],
      impact: "",
      milestone: "",
      initiatives: ""
    });
    // setExpanded(`panel${serviceFields.length}`);
  };
  const servicesRemove = (index) => {
    var id = getValues(`services.${index}.id`);
    if (id !== 0) {
      apiService(`department/service/remove?id=${id}`, '', 'delete').then((result) => {
        if (result && result.data) {
          serviceRemove(index);
        }
      }).catch((err) => {
      });
    } else {
      serviceRemove(index);
    }
  };
  const handleBeneficiaries = (value, index) => {
    setValue(`services.${index}.beneficiaries`, value);
    clearErrors(`services.${index}.beneficiaries`);
  }
  const handleCategories = (value, index) => {
    debugger
    setValue(`projects.${index}.catgoryName`, value);
    clearErrors(`projects.${index}.catgoryName`);
  }

  const handleSelectSector = (value, index) => {
    setValue(`projects.${index}.sector`, value);
    clearErrors(`projects.${index}.sector`);



  };
  // const [openTeam, setOpenTeam] = useState(false)
  // const addTeamBtn = () => {
  //   setOpenTeam(true)
  // };
  // const [teamName, setTeamName] = useState('')
  // const [teamError, setTeamError] = useState(false)
  // const handleTeamName = (value) => {
  //   setTeamName(value)
  //   setTeamError(false)
  // }
  // const handleTeamSave = () => {
  //   if (teamName !== '') {
  //     teamAppend({
  //       id: 0,
  //       name: teamName,
  //       userId: ""
  //     });
  //     setTeamError(false)
  //     setTeamName('')
  //     setOpenTeam(false)
  //   } else {
  //     setTeamError(true)
  //   }

  // }
  const [isManOpen, setIsManOpen] = useState(false);
  const handleOffTeamDropDown = (options) => {
    setIsManOpen(false)
    var val = options
    if (val !== '') {
      var getFrom = getValues('teams')

      if (getFrom !== undefined && getFrom.length === 1 && getFrom[0].name === '') {
        setValue(`teams.${0}.id`, 0)
        setValue(`teams.${0}.name`, val.firstName)
        setValue(`teams.${0}.userId`, val.id)
      } else {
        teamAppend({
          id: 0,
          name: val.firstName,
          userId: val.id
        });
      }

    }

  }
  const handleTeamDelete = (index) => {
    var id = getValues(`teams.${index}.id`)
    if (id !== 0) {
      apiService(`department/team/remove?id=${id}`, '', 'delete').then((result) => {
        if (result && result.data) {
          teamRemove(index)
        }
      }).catch((err) => {

      });
    } else {
      teamRemove(index)
    }
  }

  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName, fileUrl) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png") {
            setValue(filekey, "");
            setValue(fileName, "");
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              setValue(fileUrl, logourl1)
              clearErrors(filekey);
              clearErrors(fileName);
              clearErrors(fileUrl);
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File size exceeds 5MB",
            "Please check your file size"
          );
        }
      }
    }
  };

  const toolbarOptions = {
    toolbar: {
      container: [
        // [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        // [{ 'size': ['small', false, 'large'] }], 
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        // ['link', 'image', 'video'],
        ['clean']
      ],
    },
  };

  const handleContentChange = (value) => {
    if (value !== "<p><br></p>") {
      setValue('about', value)
      clearErrors('about')
    } else {
      setValue('about', '')
      setError('about', {
        message: "Description is required"
      })
    }
  };

  const onSubmit = (data) => {
    setPageLoading(true)
    data.authorityUserId = authorityUserId;
    if (data.state === "Tamil Nadu") {
      var districtId = district.filter((list) => list.name === data.district)
      data.districtId = districtId[0].id
    }
    if (data.branches) {
      data.branches.forEach(element => {
        if (element.state === "Tamil Nadu") {
          var districtId = district.filter((list) => list.name === element.district)
          element.districtId = districtId[0].id
        }
      });
    }
    if (data.projects) {
      data.projects.forEach(element => {
        var categoryId = categoryList.filter((list) => list.category === element.catgoryName)
        element.categoryId = categoryId[0].id
        // let catePush = []
        // element.categories.filter((category) => {

        // })
        let secpush = []
        element.sector.filter((sector) => {
          var sectorId = sectors.filter((list) => list.name === sector)
          debugger
          return secpush.push(sectorId[0].id)
        })
        // element.categoryIds = catePush
        element.sectorIds = secpush
      });

    }

    data.authorityEmail = cryptoJS(data.authorityEmail)
    console.log(data)

    apiService("department/save", data, "post")
      .then((result) => {
        setPageLoading(false)
        if (result.data.responseStatus === "Success") {
          notifyService("success", "Success", "");
          navigator("/admin/department/list");
        }
      })
      .catch((err) => { });
  };
  const onError = (event) => {
    console.log("error Data:", event);
    notifyService(
      "danger",
      "Submit Failed",
      "Please check the mandatory fields"
    );
  };
  const handleReset = () => {
    reset();
    navigator("/admin/department/list");
  };

  return (
    <div className="deap-Add-form">
      <div className='event_add_header my-4'>
        <div className='d-flex w-100 align-items-center'>
          <div>
            <p className="back-btns mb-0" onClick={handleReset}>
              <FaArrowLeft />
              <span style={{ marginLeft: "5px" }}>Back</span>
            </p>
          </div>
          <div className='m-auto'>
            <h4 className='font-color-black'>{departmentId ? 'Update' : 'Add'} Departments</h4>
            <p>See and add your department information</p>
          </div>
        </div>
      </div>
      <div className="dep-add-form">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <h5 className="mb-4 dep-titles">Department Details</h5>
          <div className="mb-4">
            <div className="event_add_details_div">
              <Grid container direction="row" alignItems="flex-start" justifyContent="start" spacing={3} >
                {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className="text-center meeting-logo-mobile">
                <div className="logo_div">
                  <img
                    src={logoDisplayUrl}
                    className="user_logo"
                    alt={logoDisplayName}
                  />
                  <div className="camera_icon">
                    <Controller
                      name="logo"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <Button
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              "logo",
                              "logoName",
                              setLogoDisplayValue,
                              setLogoDisplayName,
                              setLogoDisplayUrl,
                              "image"
                            )
                          }
                          value={logoValue}
                          component="label"
                          startIcon={<CameraAltIcon />}
                        >
                          <VisuallyHiddenInput type="file" />
                        </Button>
                      )}
                    />
                  </div>
                </div>
                <div>
                  <span className="fn-12">
                    Department Logo <span className="text-danger">*</span>
                  </span>
                </div>
                <div>
                  <span className="fn-12">Recommend Size (128px * 128px)</span>
                </div>
                <div>
                  <span className="fn-12">
                    Maximum 5 mb allowed doc (png, jpg, jpeg){" "}
                    <span className="text-danger">*</span>
                  </span>
                </div>
                {logoInvalid === true && (
                  <span className="text-danger">
                    Department Logo is required
                  </span>
                )}
              </Grid> */}
                <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                  <Grid container direction="row " alignItems="flex-start" justifyContent="start" spacing={3} >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="input_background">
                        <Controller
                          name="name"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Department Name is required" }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Department Name"
                              placeholder="Please Enter Department Name"
                              fullWidth
                              type="text"
                              {...field}
                              disabled
                              error={Boolean(
                                errors.name && errors.name
                              )}
                            // InputLabelProps={{
                            //   className: "dep-text-label",
                            // }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.name && errors.name.message}
                        </FormHelperText>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="input_background dropdown">
                        <Controller
                          name="authorityName"
                          control={control}
                          defaultValue=""
                          rules={{ required: "AuthorityUser Name is required" }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="AuthorityUser Name"
                              placeholder="Please Enter authority Name"
                              fullWidth
                              // onInput={handleSearchChange}
                              type="text"
                              {...field}
                              disabled
                              error={Boolean(
                                errors.authorityName && errors.authorityName
                              )}
                            />
                          )}
                        />
                        {/* {isOpen && (
                          <div className="dropdown-menu w-100">
                            {filteredOptions.length > 0 ? (
                              filteredOptions.map((option, index) => (
                                <div
                                  key={index}
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleOptionClick(option, option.firstName)
                                  }
                                >
                                  {option.firstName}
                                </div>
                              ))
                            ) : (
                              <div className="dropdown-item">
                                No options found
                              </div>
                            )}
                          </div>
                        )} */}
                        <FormHelperText className="text-danger ml-0">
                          {errors.authorityName &&
                            errors.authorityName.message}
                        </FormHelperText>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="input_background">
                        <Controller
                          name="authorityEmail"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Organization Mail ID is required",
                            validate: validateEmail,
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Organization Mail ID"
                              placeholder="Please Enter Organization Mail ID"
                              fullWidth
                              type="text"
                              {...field}
                              error={Boolean(errors.authorityEmail && errors.authorityEmail)}
                              disabled
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.authorityEmail && errors.authorityEmail.message}
                        </FormHelperText>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="input_background">
                        <Controller
                          name="designation"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Designation is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Designation"
                              placeholder="Please Enter Designation"
                              fullWidth
                              type="text"
                              {...field}
                              error={Boolean(errors.designation && errors.designation)}
                              disabled
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.designation && errors.designation.message}
                        </FormHelperText>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className="text-center meeting-logo-lab">
                  <div className="logo_div">
                    <img src={getValues('logoUrl') ? getValues('logoUrl') : '/images/user.png'} className="user_logo" alt={''} />
                    <div className="camera_icon">
                      <Controller
                        name="logoName"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Logo is required' }}
                        render={({ field }) => (
                          <Button
                            onChange={(e) => handleFileUpload(e, "logo", "logoName", 'logoUrl')}
                            component="label"
                            startIcon={<CameraAltIcon />}
                          >
                            <VisuallyHiddenInput type="file" />
                          </Button>
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <span className="fn-12">
                      Department Logo <span className="text-danger">*</span>
                    </span>
                  </div>
                  <div>
                    <span className="fn-12">Recommend Size (128px * 128px)</span>
                  </div>
                  <div>
                    <span className="fn-12">
                      Maximum 5 mb allowed doc (png, jpg, jpeg){" "}
                      <span className="text-danger">*</span>
                    </span>
                  </div>
                  <div>
                    <FormHelperText className="text-danger text-center">
                      {errors.logoName && errors.logoName.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <div className="input_background mb-3">
                    <Controller
                      name="contactNumber"
                      control={control}
                      defaultValue=""
                      // rules={{ required: "Contact Number is required" }}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Contact Number"
                          placeholder="Please Enter Contact / Lanline Number"
                          fullWidth
                          type="text"
                          {...field}
                          // onKeyDown={(e) => validateNumberonly(e)}
                          // inputProps={{ maxLength: 11, minLength: 7 }}
                          error={Boolean(
                            errors.contactNumber && errors.contactNumber
                          )}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.contactNumber && errors.contactNumber.message}
                    </FormHelperText>
                  </div>
                  <div className="input_background mb-3">
                    <Controller
                      name="departmentSize"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Department Size is required" }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Department Size"
                          placeholder="Please Enter Department Size"
                          fullWidth
                          type="text"
                          {...field}
                          onKeyDown={(e) => validateNumberonly(e)}
                          inputProps={{ maxLength: 5, minLength: 1 }}
                          error={Boolean(
                            errors.departmentSize && errors.departmentSize
                          )}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.departmentSize && errors.departmentSize.message}
                    </FormHelperText>
                  </div>
                  <div className="input_background mb-3">
                    <Controller
                      name="tagline"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Tagline is required" }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Tagline"
                          placeholder="Please Enter Tagline"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.tagline && errors.tagline)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.tagline && errors.tagline.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                  <div className="input_background">
                    <Controller
                      name="about"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Description is required" }}
                      render={({ field }) => (
                        // <TextField
                        //   {...field}
                        //   id="outlined-multiline-static"
                        //   label="Enter your Description *"
                        //   fullWidth
                        //   multiline
                        //   rows={2}
                        //   defaultValue=""
                        //   error={Boolean(errors.about && errors.about)}
                        // />
                        <ReactQuill
                          {...field}
                          theme="snow"
                          value={field.value}
                          modules={toolbarOptions}
                          onChange={(content, delta, source, editor) => {
                            handleContentChange(content);
                            field.onChange(content);
                          }}
                          placeholder="Enter your Description"
                          fullWidth
                          type="text"
                          multiline
                        // style={{ minHeight: '150px' }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.about && errors.about.message}
                    </FormHelperText>
                  </div>
                </Grid>
              </Grid>
            </div>

            <h5 className="mb-4 dep-titles mt-3 ">Head Quarters Address</h5>
            <div className="event_add_details_div">
              <Grid container direction="row" alignItems="flex-start" justifyContent="start" spacing={3} >
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="input_background">
                    <Controller
                      name="address"
                      control={control}
                      defaultValue=""
                      rules={{ required: false, }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Address"
                          placeholder="Please Enter Address"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.address && errors.address)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.address && errors.address.message}
                    </FormHelperText>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="input_background">
                    <Controller
                      name="state"
                      control={control}
                      defaultValue=""
                      rules={{ required: false, }}
                      render={({ field }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          {...field}
                          options={states}
                          onChange={(e, selectedOptions) =>
                            handleSelectState(selectedOptions)
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="State" error={Boolean(errors.state && errors.state)} />
                          )}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.state && errors.state.message}
                    </FormHelperText>
                  </div>
                </Grid>
                {
                  getValues("state") === "Tamil Nadu" ? (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <div className="input_background">
                          <Controller
                            name="district"
                            control={control}
                            defaultValue=""
                            rules={{ required: false, }}
                            render={({ field, fieldState: { error } }) => (
                              <Autocomplete
                                {...field}
                                disableClearable
                                disablePortal
                                id="combo-box-demo"
                                options={district.map((option) => option.name)}
                                onChange={(e, selectedOption) =>
                                  handleSelectDistrict(selectedOption)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="District"
                                    error={Boolean(errors.district && errors.district)}
                                  />
                                )}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.district && errors.district.message}
                          </FormHelperText>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={3} xl={3} >
                        <div className="input_background">
                          <Controller
                            name="district"
                            control={control}
                            defaultValue=""
                            rules={{ required: false, }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="City"
                                placeholder="Please Enter City"
                                fullWidth
                                type="text"
                                {...field}
                                error={Boolean(errors.district && errors.district)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.district && errors.district.message}
                          </FormHelperText>
                        </div>
                      </Grid>
                    </>
                  )}
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="input_background">
                    <Controller
                      name="pincode"
                      control={control}
                      defaultValue=""
                      rules={{ required: false, }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Pincode"
                          placeholder="Please Enter Pincode"
                          fullWidth
                          type="text"
                          {...field}
                          onKeyDown={(e) => validateNumberonly(e)}
                          inputProps={{ maxLength: 6, minLength: 6 }}
                          error={Boolean(
                            errors.pincode && errors.pincode
                          )}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.pincode && errors.pincode.message}
                    </FormHelperText>
                  </div>
                </Grid>
              </Grid>
            </div>

            <Grid container spacing={3}>
              <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                <div className="div_header">
                  <div className="d-flex align-items-center my-3">
                    <div>
                      <h5 className="dep-titles">Branches Address</h5>
                    </div>
                    <div className="ml-auto">
                      <Button
                        className='add_icon_btn'
                        onClick={() => addBrancheAddressDetails("")}
                      >
                        + Add
                      </Button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="event_add_details_div">
                    {brancheFields.map((field, index, type) => (
                      <div key={field.id} className="Branches-Address">
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <div className="input_background">
                              <Controller
                                name={`branches.${index}.street`}
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: false,
                                }}
                                render={({ field }) => (
                                  <TextField
                                    variant="outlined"
                                    label="Address"
                                    placeholder="Enter Address"
                                    fullWidth
                                    type="text"
                                    {...field}
                                    error={Boolean(
                                      errors.branches?.[index]?.street &&
                                      errors.branches?.[index]?.street
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.branches?.[index]?.street &&
                                  errors.branches?.[index]?.street.message}
                              </FormHelperText>
                            </div>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <div className="input_background">
                              <Controller
                                name={`branches.${index}.state`}
                                control={control}
                                defaultValue=""
                                rules={{ required: false, }}
                                render={({ field }) => (
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    {...field}
                                    options={states}
                                    onChange={(e, selectedOptions) =>
                                      handleaddSelectState(selectedOptions, index)
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} label="State" error={Boolean(
                                        errors.branches?.[index]?.state &&
                                        errors.branches?.[index]?.state
                                      )} />
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.branches?.[index]?.state &&
                                  errors.branches?.[index]?.state.message}
                              </FormHelperText>
                            </div>
                          </Grid>
                          {
                            getValues(`branches.${index}.state`) === "Tamil Nadu" ? (
                              <>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                  <div className="input_background">
                                    <Controller
                                      name={`branches.${index}.district`}
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: false, }}
                                      render={({ field, fieldState: { error } }) => (
                                        <Autocomplete
                                          {...field}
                                          disableClearable
                                          disablePortal
                                          id="combo-box-demo"
                                          options={district.map(
                                            (option) => option.name
                                          )}
                                          onChange={(e, selectedOption) =>
                                            handleaddSelectDistrict(
                                              selectedOption,
                                              index
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField   {...params} label="District" error={Boolean(
                                              errors.branches?.[index]?.district &&
                                              errors.branches?.[index]?.district
                                            )}
                                            />
                                          )}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.branches?.[index]?.district &&
                                        errors.branches?.[index]?.district.message}
                                    </FormHelperText>
                                  </div>
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                  <div className="input_background">
                                    <Controller
                                      name={`branches.${index}.district`}
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: false, }}
                                      render={({ field }) => (
                                        <TextField
                                          variant="outlined"
                                          label="City"
                                          placeholder="Please Enter City"
                                          fullWidth
                                          type="text"
                                          {...field}
                                          error={Boolean(
                                            errors.branches?.[index]?.district &&
                                            errors.branches?.[index]?.district
                                          )}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.branches?.[index]?.district &&
                                        errors.branches?.[index]?.district.message}
                                    </FormHelperText>
                                  </div>
                                </Grid>
                              </>
                            )}
                          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <div className="input_background">
                              <Controller
                                name={`branches.${index}.pincode`}
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: false,
                                }}
                                render={({ field }) => (
                                  <TextField
                                    variant="outlined"
                                    label="Pincode"
                                    placeholder="Enter Pincode"
                                    fullWidth
                                    type="text"
                                    {...field}
                                    onKeyDown={(e) => validateNumberonly(e)}
                                    inputProps={{ maxLength: 6, minLength: 6 }}
                                    error={Boolean(
                                      errors.branches?.[index]?.pincode &&
                                      errors.branches?.[index]?.pincode
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.branches?.[index]?.pincode &&
                                  errors.branches?.[index]?.pincode.message}
                              </FormHelperText>
                            </div>
                          </Grid>
                        </Grid>
                        {brancheFields.length !== 1 && (
                          <Button
                            className="red_btn"
                            onClick={() => brancheAddressRemove(index)}
                          >
                            <DeleteForeverIcon />
                          </Button>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
            </Grid>
            <h5 className="mb-4 mt-3 dep-titles">Social Media Links</h5>
            <div className="event_add_details_div">
              <Grid
                container
                direction="row "
                alignItems="flex-start"
                justifyContent="start"
                spacing={3}
              >
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="input_background">
                    <Controller
                      name="facebook"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Facebook"
                          placeholder="Please Enter Facebook URL"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.facebook && errors.facebook)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.facebook && errors.facebook.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="input_background">
                    <Controller
                      name="youtube"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Youtube"
                          placeholder="Please Enter Youtube URL"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.youtube && errors.youtube)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.youtube && errors.youtube.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="input_background">
                    <Controller
                      name="instagram"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Instagram"
                          placeholder="Please Enter Instagram URL"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.instagram && errors.instagram)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.instagram && errors.instagram.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="input_background">
                    <Controller
                      name="twitter"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Twitter"
                          placeholder="Please Enter Twitter URL"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.twitter && errors.twitter)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.twitter && errors.twitter.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="input_background">
                    <Controller
                      name="linkedIn"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Linkedin"
                          placeholder="Please Enter Linkedin URL"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.linkedIn && errors.linkedIn)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.linkedIn && errors.linkedIn.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="input_background">
                    <Controller
                      name="whatsapp"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Whatsapp"
                          placeholder="Please Enter Whatsapp URL"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.whatsapp && errors.whatsapp)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.whatsapp && errors.whatsapp.message}
                    </FormHelperText>
                  </div>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                <>
                  <div className="d-flex align-items-center my-3">
                    <div>
                      <h5 className="dep-titles">Projects</h5>
                    </div>
                    <div className="ml-auto">
                      <Button
                        className='add_icon_btn'
                        onClick={() => addServicesBtn()}
                      >
                        + Add
                      </Button>
                    </div>
                  </div>
                  {serviceFields.map((field, index) => (
                    <Accordion
                      key={field.id}
                      expanded={expanded === index}
                      onChange={handleChange(index)}
                    >
                      <AccordionSummary id={index} >
                        <div className="d-flex w-100 align-item-center justify-content-between">
                          <h5>{field.name ? field.name : ` Project ${index + 1}`}</h5>
                          {serviceFields.length !== 1 && (
                            <Button
                              className="red_btn"
                              onClick={() => servicesRemove(index)}
                            >
                              <DeleteForeverIcon />
                            </Button>
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3}>
                          <Grid item lg={3} md={6} sm={12} xs={12}>
                            <div className="input_background">
                              <Controller
                                name={`projects.${index}.projectTitle`}
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "Name is required",
                                }}
                                render={({ field }) => (
                                  <TextField
                                    variant="outlined"
                                    label="Name"
                                    placeholder="Enter Project title"
                                    fullWidth
                                    type="text"
                                    {...field}
                                    error={Boolean(
                                      errors.projects?.[index]?.projectTitle &&
                                      errors.projects?.[index]?.projectTitle
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.services?.[index]?.name &&
                                  errors.services?.[index]?.name.message}
                              </FormHelperText>
                            </div>
                          </Grid>
                          {/* <Grid item lg={3} md={6} sm={12} xs={12}>
                            <div className="input_background">
                              <Controller
                                name={`services.${index}.beneficiaries`}
                                control={control}
                                defaultValue={[]}
                                rules={{
                                  required: "beneficiaries is required",
                                }}
                                render={({ field }) => (
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    {...field}
                                    multiple={true}
                                    limitTags={1}
                                    value={Array.isArray(field.value) ? field.value : []}
                                    options={beneficiariesList}
                                    onChange={(e, selectedOptions) =>
                                      handleBeneficiaries(selectedOptions, index)
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} label="Target Users / Beneficiaries" placeholder="Select Target Users / Beneficiaries" error={Boolean(
                                        errors.services?.[index]?.beneficiaries &&
                                        errors.services?.[index]?.beneficiaries
                                      )} />
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.services?.[index]?.beneficiaries &&
                                  errors.services?.[index]?.beneficiaries.message}
                              </FormHelperText>
                            </div>
                          </Grid> */}
                          <Grid item lg={3} md={6} sm={12} xs={12}>
                            <div className="input_background">
                              <Controller
                                name={`projects.${index}.catgoryName`}
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "Categories is required",
                                }}
                                render={({ field }) => (
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    {...field}
                                    // multiple={true}
                                    // limitTags={1}
                                    // value={Array.isArray(field.value) ? field.value : ''}
                                    options={Array.isArray(categoryList) ? categoryList.map((option) => option.category) : []}
                                    onChange={(e, selectedOptions) =>
                                      handleCategories(selectedOptions, index)
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} label="Categories" placeholder="Select Categories" error={Boolean(
                                        errors.projects?.[index]?.catgoryName &&
                                        errors.projects?.[index]?.catgoryName
                                      )} />
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.projects?.[index]?.categories &&
                                  errors.projects?.[index]?.categories.message}
                              </FormHelperText>
                            </div>
                          </Grid>
                          <Grid item lg={3} md={6} sm={12} xs={12}>
                            <div className="input_background">
                              <Controller
                                name={`projects.${index}.sector`}
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "Sector is required",
                                }}
                                render={({ field }) => (
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    {...field}
                                    multiple={true}
                                    limitTags={1}
                                    value={Array.isArray(field.value) ? field.value : []}
                                    options={Array.isArray(sectors) ? sectors.map((option) => option.name) : []}
                                    onChange={(e, selectedOptions) =>
                                      handleSelectSector(selectedOptions, index)
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} label="Sector" placeholder="Select Sectors" error={Boolean(
                                        errors.projects?.[index]?.sector &&
                                        errors.projects?.[index]?.sector
                                      )} />
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.projects?.[index]?.sector &&
                                  errors.projects?.[index]?.sector.message}
                              </FormHelperText>
                            </div>
                          </Grid>
                          <Grid item lg={3} md={6} sm={12} xs={12}>
                            <div className="input_background">
                              <Controller
                                name={`projects.${index}.stages`}
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <Autocomplete
                                    disableClearable
                                    multiple={true}
                                    options={stages}
                                    getOptionLabel={(option) => option}
                                    defaultValue={[]}
                                    value={value || []}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Stage "
                                        placeholder="Select Stage "
                                        error={!!error}
                                        helperText={error ? "Stage is required" : ""}
                                      />
                                    )}
                                    fullWidth
                                    margin="normal"
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.projects?.[index]?.stages &&
                                  errors.projects?.[index]?.stages.message}
                              </FormHelperText>
                            </div>
                          </Grid>
                          {/* <Grid item lg={3} md={6} sm={12} xs={12}>
                            <div className="input_background">
                              <Controller
                                name={`services.${index}.impact`}
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "Impact Created is required",
                                }}
                                render={({ field }) => (
                                  <TextField
                                    variant="outlined"
                                    label="Impact Created so far"
                                    placeholder="Enter Impact Created so far"
                                    fullWidth
                                    type="text"
                                    {...field}
                                    error={Boolean(
                                      errors.services?.[index]?.impact &&
                                      errors.services?.[index]?.impact
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.services?.[index]?.impact &&
                                  errors.services?.[index]?.impact.message}
                              </FormHelperText>
                            </div>
                          </Grid> */}
                          {/* <Grid item lg={3} md={6} sm={12} xs={12}>
                            <div className="input_background mb-3">
                              <Controller
                                name={`services.${index}.milestone`}
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "Mile stone is required",
                                }}
                                render={({ field }) => (
                                  <TextField
                                    variant="outlined"
                                    label="Current Progress / Milestone"
                                    placeholder="Enter Current Progress / Milestone"
                                    fullWidth
                                    type="text"
                                    {...field}
                                    error={Boolean(
                                      errors.services?.[index]?.milestone &&
                                      errors.services?.[index]?.milestone
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.services?.[index]?.milestone &&
                                  errors.services?.[index]?.milestone.message}
                              </FormHelperText>
                            </div>
                            <div className="input_background">
                              <Controller
                                name={`services.${index}.initiatives`}
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "Projects/ Initiatives is required",
                                }}
                                render={({ field }) => (
                                  <TextField
                                    variant="outlined"
                                    label="No. of Projects/ Initiatives"
                                    placeholder="Enter No. of Projects/ Initiatives"
                                    fullWidth
                                    type="number"
                                    {...field}
                                    error={Boolean(
                                      errors.services?.[index]?.initiatives &&
                                      errors.services?.[index]?.initiatives
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.services?.[index]?.initiatives &&
                                  errors.services?.[index]?.initiatives.message}
                              </FormHelperText>
                            </div>
                          </Grid> */}
                          <Grid item lg={9} md={12} sm={12} xs={12}>
                            <div className="input_background">
                              <Controller
                                name={`projects.${index}.description`}
                                control={control}
                                defaultValue=""
                                rules={{ required: "Description is required" }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id="outlined-multiline-static"
                                    label="Enter your Description"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    defaultValue=""
                                    error={Boolean(
                                      errors.services?.[index]?.description &&
                                      errors.services?.[index]?.description
                                    )}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.services?.[index]?.description &&
                                  errors.services?.[index]?.description.message}
                              </FormHelperText>
                            </div>
                          </Grid>

                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </>
              </Grid>
            </Grid>
            <div className='mb-4'>
              <div className='special_title_header d-flex align-items-center mb-3'>
                <h5 className="mb-4 mt-3 dep-titles">Team</h5>
                <div className='ml-3'>
                  <SearchableDropdown isOpen={isManOpen} setIsOpen={setIsManOpen} handleOffDropDown={handleOffTeamDropDown} lblName="Search Name" />
                </div>
                {/* <div className='ml-auto d-flex align-items-center'>
                  <div className="mr-3">
                    {
                      openTeam === true && (
                        <div className="input_background">
                          <TextField
                            label="Team Name"
                            placeholder="Enter Team Name"
                            fullWidth
                            value={teamName}
                            onInput={(e) => handleTeamName(e.target.value)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CheckIcon className="c-pointer c-green" onClick={handleTeamSave} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {
                            teamError === true && (
                              <FormHelperText className="text-danger">
                                Team Name is required
                              </FormHelperText>
                            )
                          }

                        </div>
                      )
                    }

                  </div>
                  <div>
                    <Button className='add_icon_btn' onClick={addTeamBtn}>+ Add</Button>
                  </div>
                </div> */}
              </div>
              {
                teamFields.length > 0 && (
                  <div className='event_add_details_div'>
                    <div className="chip-rows">
                      {teamFields.map((value, index) => (
                        <Chip
                          label={value.name}
                          onDelete={() => handleTeamDelete(index)}
                          deleteIcon={
                            <RiCloseCircleFill style={{ color: "#fff" }} />
                          }
                          sx={{ background: "#0d1232", color: "#fff" }}
                        />
                      ))
                      }
                    </div>
                  </div>
                )
              }

            </div>
            <div className="text-center mb-3 mt-4 w-100">
              <Button onClick={handleReset} className="discard-btn">
                Close
              </Button>
              <Button type="submit" className="publish-button">
                {departmentId ? 'Update' : 'Save'}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DepartmentAdd;
