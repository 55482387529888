// import React from 'react';
// import { DataProvider } from './context/DataContext';
// import { ReactNotifications } from 'react-notifications-component';
// import './App.css';
// import 'react-notifications-component/dist/theme.css';
// import Baseroute from './components/Baseroute';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Undermaintenance from './components/common-pages/under-maintenance-page/Undermaintenance';
// import Sesstiontimeout from './components/common-pages/sesstion-time-out-page/Sesstiontimeout';
// import Pagenotfound from './components/common-pages/page-not-found/Pagenotfound';
// import ScrolltoTop from './components/common-pages/ScrolltoTop';

// function App() {
  
//   return (
//     <div className="App">
//       <ReactNotifications />
//       <DataProvider>
//         <ScrolltoTop />
//         <Baseroute />
//       </DataProvider>
//       <Routes>
//         <Route path='/under-maintenance' element={<Undermaintenance />} />
//         <Route path='/sesstion-timeout' element={<Sesstiontimeout />} />
//         <Route path='404' element={<Pagenotfound />} />
//       </Routes>
//     </div >
//   );
// }

// export default App;

// "start": "NODE_ENV=development node scripts/start.js",
//   "start-prod": "NODE_ENV=production node scripts/start.js",
//     "build-dev": "NODE_ENV=development node scripts/build.js",
//       "build-prod": "NODE_ENV=production node scripts/build.js",


import React, { useEffect } from 'react';
import { DataProvider } from './context/DataContext';
import { ReactNotifications } from 'react-notifications-component';
import './App.css';
import 'react-notifications-component/dist/theme.css';
import Baseroute from './components/Baseroute';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Undermaintenance from './components/common-pages/under-maintenance-page/Undermaintenance';
import Sesstiontimeout from './components/common-pages/sesstion-time-out-page/Sesstiontimeout';
import Pagenotfound from './components/common-pages/page-not-found/Pagenotfound';
import ScrolltoTop from './components/common-pages/ScrolltoTop';
import $ from 'jquery';

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    let uselang = localStorage.getItem("lang");
    if (uselang == null) {
      $("html").attr("lang", "en");
      localStorage.setItem("lang", "en");
    } else {
      $("html").attr("lang", uselang);
      localStorage.setItem("lang", uselang);
    }
  }, []);
  return (
    <div className="App">
      <ReactNotifications />
      <DataProvider>
        <ScrolltoTop />
        <Baseroute />
      </DataProvider>
      <Routes>
        <Route path='/under-maintenance' element={<Undermaintenance />} />
        <Route path='/sesstion-timeout' element={<Sesstiontimeout />} />
        <Route path='404' element={<Pagenotfound />} />
      </Routes>
    </div>
  );
}

export default App;
